/**
 * @copyright 2017 @ Tech Seed Labs
 * @author rocachien
 * @create 2017/08/12 10:37
 * @update 2017/08/12 10:37
 * @file src/components/header/search-bar.js
 */

import { Button, Image, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as messageActions from "../../redux/message/message_actions";
import * as mainActions from "../../redux/main/main_actions";
import './header.css';
import EllipsisText from "react-ellipsis-text";
import Api from '../../services/api.js';

class Header extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            adminName: "No Name",
            popupLogOut: false,
            notifData: [],
            loadingNotifs: false,
            currentNotifPage: 0,
            allowScroll: true,
            lastOffsetLoaded: 0,
        };
        this.refreshInterval = null;
    };


    onLogout = () => {
        this.refs.OverlayTrigger.hide();
        this.setState({
            popupLogOut: !this.state.popupLogOut
        })
    };

    logOut = () => {
        if (this.props.onLogout) {
            this.props.onLogout();
        }
    };

    goMess = () => {
        this.props.messageActions.newMessage(null);
        window.location.href = this.props.messageNew.notification.click_action;
    };

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 5;


        if (bottom && this.state.lastOffsetLoaded < e.target.scrollTop) {
            console.log('notif bottom reached');
            this.getNotificationData(this.state['currentNotifPage'] * 10);
            this.state.currentNotifPage = this.state['currentNotifPage'] + 1;

            this.state.lastOffsetLoaded = e.target.scrollTop + 30;
        }
    }


    componentDidMount() {
        this.getNotificationData(this.state.currentNotifPage * 10);
        this.badgeInterval = setInterval(() => this.props.mainActions.getNumberBadge((err, data) => {
            console.log("getNumberbadge", err, data);
            if (data && data.badge) {
                this.props.mainActions.updateNumberBadge(data.badge, (err, data) => {
                    console.log("updateNumberBadge", err, data);
                });
            } else {
                console.log('Data get number badge is error')
            }
        }), 5000);

    }

    markAllSeenNotifsAsRead() {
        console.log("On exit called")

        /*if (this.state.notifData.length>0){
            for(let ind = 0;ind<this.state.notifData.length;ind++){
                this.markNotificationRead(this.state.notifData[ind]);
            }
        }*/
    }

    componentWillUnmount() {
        clearInterval(this.badgeInterval);
    }


    markNotificationRead(notifId) {
        console.log("Tryuing it")
        Api.put('/notification/edit', {
            id: notifId,
            read: 'Y'
        })
            .then(response => response)
            .then(data => {
                console.log("Tryuing it")
                console.log(data);
            })
    }


    getNotificationData = (skip = 0, refresh = false) => {
        Api.put('/notification/search', {
            sort: '[{"createdAt": "DESC"}]',
            skip: skip,
            limit: 10
        })
            .then(response => response)
            .then(data => {

                let newList = this.state.notifData.concat(data.data);

                this.setState({ notifData: newList });
                console.log("got notifications")
                console.log(this.state.notifData)
            })

    }

    refreshNotifs = (e) => {
        this.setState({ notifData: [] })
        this.state.currentNotifPage = 1;

        this.state.lastOffsetLoaded = 0;
        this.getNotificationData(0);

        this.props.mainActions.getNumberBadge((err, data) => {
            console.log("getNumberbadge", err, data);
            if (data && data.badge) {
                this.props.mainActions.updateNumberBadge(data.badge, (err, data) => {
                    console.log("updateNumberBadge", err, data);
                });
            } else {
                console.log('Data get number badge is error')
            }
        });
    }
    render() {
        let { type, login, numberBadge } = this.props;
        console.log(numberBadge);
        let size = null;
        if (type === 0) {
            size = {
                logo: 40,
                header: 60
            };
        } else {
            size = {
                logo: 40,
                header: 60
            };
        }

        let dataMenu = [
            {
                name: 'Account',
                link: '/my-account',
                icon: require('../../assets/images/side-menu/setting-logo.png'),
                iconW: require('../../assets/images/side-menu/setting-logo-w.png')              
               
            },
            {
                name:'User Management',
                link:'/user-management',
                icon: require('../../assets/images/side-menu/icon-profile.png'),
                iconW: require('../../assets/images/side-menu/icon-profile-w.png')
            },
            {
                name: 'Help',
                link: 'https://teemieapp.com/contact/',
                icon: require('../../assets/images/side-menu/icon-help.png'),
                iconW: require('../../assets/images/side-menu/icon-help-w.png')
            },
            {
                name: 'T&C',
                link: 'https://teemieapp.com/terms-of-usage/',
                icon: require('../../assets/images/side-menu/icon-TC.png'),
                iconW: require('../../assets/images/side-menu/icon-TC-w.png')
            },
            {
                name: 'Log Out',
                icon: require('../../assets/images/side-menu/icon-logout.png'),
                iconW: require('../../assets/images/side-menu/icon-logout-w.png')
            }
        ];


        let badge = numberBadge && numberBadge !== '0' ? (parseInt(numberBadge, 10) > 99 ? '99+' : numberBadge) : null;
        return (
            <div className="header-container align-between"
                style={{ boxShadow: '0 2px 5px rgba(0,0,0,0.2)', height: size.header, zIndex: 99999 }}>
                <div>
                    <Image className="header-logo" style={{ width: size.logo }}
                        src={require('../../assets/images/header/logo-header.png')} />
                </div>
                {(!login || type === 0) && (
                    <div className="display_row align-right">
                        <img alt="" className="icon-social-header"
                            src={require('../../assets/images/header/icon-instagram.png')} />
                        <img alt="" className="icon-social-header"
                            src={require('../../assets/images/header/icon-facebook.png')} />
                        <img alt="" className="icon-social-header"
                            src={require('../../assets/images/header/icon-twitter.png')} />
                    </div>
                )}
                {login &&
                    <div className="display_row align-center" style={{ position: "relative" }}>
                        <OverlayTrigger ref={"OverlayTrigger"} placement="bottom" rootClose trigger="click"
                            onClick={this.refreshNotifs}
                            overlay={
                                <Tooltip id="notif" bsClass="fade in tooltip bottom popup-menu-profile"
                                    onScroll={this.handleScroll}

                                >

                                    <div className="notif-menu-title">Notifications</div>
                                    <div className="notif-menu-desc">Keep up to date with your teams activities</div>
                                    {this.state.notifData.map((item, idx) => {
                                        return <NotificationLink data={item} key={"menu-item-" + idx}
                                        />
                                    })}
                                </Tooltip>}>
                            <a className="header-notification display_row align-center"
                                style={{ fontSize: 30, cursor: 'pointer' }}
                            // onClick={this.getNotificationData}
                            >
                                <Image className="notifications-header-icon"
                                    src={require('../../assets/images/side-menu/icon-notificcation.png')} />
                                {badge && <span className="number-badge">{badge}</span>}

                            </a>
                        </OverlayTrigger>
                        <OverlayTrigger ref={"OverlayTrigger"} placement="bottom" rootClose trigger="click" overlay={
                            <Tooltip id="edit" bsClass="fade in tooltip bottom popup-menu-profile">
                                <div className="display_row" style={{ position: 'relative', margin: '0 10px 40px 10px' }}>
                                    <Image className="header-avatar"
                                           src={`${(login && login.user && login.user.avatar) ? login.user.avatar : require('../../assets/images/img-def.jpeg')}`}
                                    />
                                    {/*{badge &&  <NotificationBadge />}*/}
                                    <div className="header-name">
                                        <EllipsisText
                                            text={login.user && login.user.fullName ? login.user.fullName : 'No Name'}
                                            length={28}
                                        />
                                    </div>
                                </div>
                                {dataMenu.map((key, idx) => {
                                    return <Link data={key} key={"menu-item" + idx} onLogout={this.onLogout} />
                                })}
                            </Tooltip>}>
                            <a className="header-logout display_row align-center" style={{ fontSize: 30, cursor: 'pointer' }}>
                                <Image className="header-avatar"
                                       src={`${(login && login.user && login.user.avatar) ? login.user.avatar : require('../../assets/images/img-def.jpeg')}`}
                                />
                                    <div className="header-name">
                                        <EllipsisText
                                            text={login.user && login.user.fullName ? login.user.fullName : 'No Name'}
                                            length={28}
                                        />
                                    </div>

                                <i className="fa fa-angle-down" style={{ position: 'relative' }}/>
                            </a>
                        </OverlayTrigger>

                    </div>
                }
                <ConfirmLogOut show={this.state.popupLogOut} handleClose={this.onLogout} logOut={this.logOut} />
            </div>
        )
    }





}

class ConfirmLogOut extends Component {

    _handleClose = () => {
        if (this.props.handleClose) this.props.handleClose();
    };

    _logOut = () => {
        if (this.props.logOut) this.props.logOut();
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={this._handleClose} id="popup-confirm-roster" className="fix-modal">
                <Modal.Body>
                   
                    <Image className="logo" src={require('../../assets/images/register/icon-member.png')} />
                    <div className="content" style={{ margin: '40px 0 30px 0' }}>
                        Are you sure you wish to log out from Teemie?
                    </div>
                    <div className="display_row" style={{ padding: '10px 0' }}>
                        <Button className="login_btn_login btn-linear-orange shadow"
                            style={{ maxHeight: 50, margin: 0 }}
                            onClick={this._handleClose}>
                            CANCEL
                        </Button>
                        <Button className="login_btn_login btn-linear shadow"
                            style={{ maxHeight: 50, margin: '0 10px' }}
                            onClick={this._logOut}>
                            CONFIRM
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

class NotificationLink extends Component {
    constructor(props) {
        super(props);
        this.notifData = {};
        this.state = {}


    }

    componentDidMount() {

    }

    markNotificationRead(notifId) {
        console.log("Tryuing it")
        Api.put('/notification/edit', {
            id: notifId,
            read: 'Y'
        })
            .then(response => response)
            .then(data => {
                console.log("Tryuing it")
                console.log(data);
            })
    }

    getElapsedTime(dateString) {
        let diff = Date.now() - Date.parse(dateString);


        let diffDays = Math.trunc(diff / (1000 * 60 * 60 * 24))
        let diffHours = Math.trunc(diff / (1000 * 60 * 60))
        let diffMinutes = Math.trunc(diff / (1000 * 60))
        if (diffDays > 1) {
            return diffDays + " days ago";
        } else if (diffDays === 1) {
            return diffDays + " day ago";
        } else if (diffHours > 1) {
            return diffHours + " hours ago";
        } else if (diffHours === 1) {
            return diffHours + " hour ago";
        } else if (diffMinutes > 1) {
            return diffMinutes + " minutes ago";
        } else if (diffMinutes <= 1) {
            return "Less than 1 minute ago";
        } else {
            return "In the past"
        }

    }

    handleNotifClick = (e, data) => {
        e.preventDefault();

        let action = data.action ? data.action : null;
        if(action != null) {
            switch(data.payload.type) {
                case 'EVENT':
                    action = action.replace(RegExp('calendar?.*'), `calendar?eventId=${data.extra.event.id}&tid=${data.extra.teamId}`);
                    break;
                case 'LIKE_ANNOUNCEMENT':
                    action = '/announcements';
                    break;
                case 'ROSTER': case 'SEND_NOTI':
                    action = action.replace('roster?tid=', `roster?user=${data.sender.id}&tid=`);
                    break;
                case 'TASK':
                    action = `task-detail?taskId=${data.extra.taskId}`;
                    break;
                case 'NEW_WELCOME':
                    action = '/welcome-manager';
                    break;
            }
        }

        console.log(`The link ${action} was clicked.`);

        this.markNotificationRead(data.id);

        window.location.href = action;

    }


    render() {
        let { data } = this.props;

        if(data) {

            let notif_types = {
                "MESSAGE": require('../../assets/images/notifications/message_icon.png'),
                "CHAT": require('../../assets/images/notifications/message_icon.png'),
                "TASK": require('../../assets/images/notifications/calendar_icon.png'),
                "ROSTER": require('../../assets/images/notifications/Roster_icon.png'),
                "EVENT": require('../../assets/images/notifications/event_icon.png'),
                "ANNOUNCEMENT": require('../../assets/images/notifications/announcement_icon.png'),
                "LIKE_ANNOUNCEMENT": require('../../assets/images/notifications/announcement_icon.png'),
                "UPGRADE": require('../../assets/images/notifications/people_icon.png'),
                "NEW_WELCOME":require('../../assets/images/notifications/people_icon.png'),
            }


            let notifTypeIcon = notif_types[data.payload.type]
            if (notifTypeIcon === null || notifTypeIcon === undefined) {
                notifTypeIcon = require('../../assets/images/notifications/people_icon.png')
            }

            return (

                <a className={data.read === 'N' ? 'notif-unread' : 'notif-read' + " link notification-link"}

                    href="#" onClick={(e) => this.handleNotifClick(e, data)}
                    target={data.target ? data.target : null}

                >
                    <Image ref={"icon"} className="icon-link" src={data.sender.avatar} />
                    <Image ref={"type-icon"} className="notif-type-icon" src={notifTypeIcon} />
                    <div className="notif-message">
                        <div>{data.title}</div>
                        <div className="notif-elapsed-time">{this.getElapsedTime(data.createdAt)}</div>

                    </div>
                </a>
            )} else {
                return (<div/>)
            }
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

}

class Link extends Component {
    constructor(props) {
        super(props);
        this.state = {
            icon: false
        }
    }

    logOut = (data) => {
        if (data.name !== 'Log Out') return;
        if (this.props.onLogout) this.props.onLogout();
    };

    render() {
        let { data, numberBadge } = this.props;
        return (
            <a className="link"
                onMouseOver={() => {
                    this.setState({ icon: true })
                }}
                href={data.link ? data.link : null}
                target={data.target ? data.target : null}
                onClick={() => this.logOut(data)}
                onMouseOut={() => {
                    this.setState({ icon: false })
                }}>
                <Image ref={"icon"} className="icon-link" src={this.state.icon ? data.iconW : data.icon} />
                {data.name === 'Notifications' && numberBadge && <span className="number-badge">{numberBadge}</span>}
                {data.name}
            </a>
        )
    }
}




// export default Header;



export default connect(state => ({
    messageNew: state.message.messageNew,
    numberBadge: state.main.numberBadge,

}),
    (dispatch) => ({
        messageActions: bindActionCreators(messageActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
    })
)(Header);


