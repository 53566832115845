import React,{Component} from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';

import '../register/register.css'
import '../my-team-detail/my-team-detail.css'
import '../calendar/calendar.css'
import './tasks-page.css'

import CForm from "../libs/cform/cform";
import {browserHistory} from 'react-router';
import Config from "../../config";
import * as mainActions from "../../redux/main/main_actions";
import * as taskActions from "../../redux/task/task_actions";
import {Image} from "react-bootstrap";
import moment from 'moment';
import * as userActions from "../../redux/user/user_actions";

class TasksDetailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taskDetail: null,
            attachmentFiles: {}
        };
        this.dataForm1 = [
            {
                type: 'TEXT',
                key: "name",
                id: "name",
                placeholder:"Task name",
                feedback: false,
                def_value: '',
                disabled:true,
                validator: 'isRequired',
            },
            // {
            //     type: 'TEXT',
            //     key: "des",
            //     id: "des",
            //     placeholder:"Short description",
            //     feedback: false,
            //     def_value: '',
            //     disabled:true,
            //     validator: 'isRequired',
            // },
            {
                type: 'TEXT',
                key: "end",
                id: "end",
                placeholder:"Completion date",
                feedback: false,
                def_value: '',
                disabled:true,
                validator: 'isRequired',
            }
        ];
    }

    componentDidMount(){
        if(window.location.search){
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const taskIdParam = urlParams.get('taskId');

        let taskId = null;
        let userId = null;

        if (taskIdParam) {
        const params = taskIdParam.split('?userId=');
        taskId = params[0];
        userId = params[1] || null;
        }       
        
        if(!taskId){
            browserHistory.push('/task');
            return;
        }
        this.props.taskActions.detailTask({id:taskId,userid:userId},(err, data)=>{
            if(data){
                this.setState({
                    taskDetail: data
                }
                //, () => {
                    //let attachments = JSON.parse(this.state.taskDetail.attachments);
                    //attachments.forEach(() => { this.downloadFiles(attachments) });
                    //this.downloadFiles(JSON.parse(this.state.taskDetail.attachments));
                //}
            )
            }
            else if(err){
                Config.popup.show('INFO',[Config.alertMess(err)]);
                setTimeout(()=>{
                    browserHistory.goBack();
                },2000)
            }
        });
        }
    };

    // downloadFiles = (attachments) => {
    //     this.props.userActions.download(attachments, (error, resp) => {
    //         if(error) {
    //             return false;
    //         }
    //
    //         let attachmentFiles = this.state.attachmentFiles ? this.state.attachmentFiles : {};
    //
    //         resp.forEach((el) => {
    //             const buffer = el.buffer.data; // e.g., <Buffer 89 50 4e ... >
    //             const b64 = new Buffer(buffer).toString('base64');
    //             const mimeType = this.getMimeType(el.type);
    //
    //             attachmentFiles[el.fd] = { content: `data:${mimeType};base64,${b64}`, type: el.type, buffer: el.buffer };
    //         })
    //
    //
    //         this.setState({ attachmentFiles: attachmentFiles });
    //     })
    // }

    getMimeType(type) {
        switch (type) {
            case 'doc':
                return '';
            case 'docx':
                return '';

            case 'xls':
                return '';
            case 'xlsx':
                return '';

            case 'ppt':
                return '';
            case 'pptx':
                return '';

            case 'pdf':
                return '';

            case 'txt':
                return 'text/plain';

            default:
                return 'image/jpeg';
        }
    }

    renderIcon(file, type) {
        // let ext = file.split('.');
        // ext=ext[ext.length-1];
        // ext=ext.toLowerCase();
        switch (type) {
            case 'doc':
                return (<Image src={require("../../assets/images/file/icon-doc.png")}  style={{backgroundColor:'transparent'}}  className="avatar-image"/>);
            case 'docx':
                return (<Image src={require("../../assets/images/file/icon-docx.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);

            case 'xls':
                return (<Image src={require("../../assets/images/file/icon-xls.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);
            case 'xlsx':
                return (<Image src={require("../../assets/images/file/icon-xlsx.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);

            case 'ppt':
                return (<Image src={require("../../assets/images/file/icon-ppt.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);
            case 'pptx':
                return (<Image src={require("../../assets/images/file/icon-pptx.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);

            case 'pdf':
                return (<Image src={require("../../assets/images/file/icon-pdf.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);

            case 'txt':
                return (<Image src={require("../../assets/images/file/icon-txt.png")} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);

            default:
                return (<Image src={file} style={{backgroundColor:'transparent'}}  className="avatar-image"/>);
        }
    }

    render() {
        let {taskDetail, attachmentFiles} = this.state;
        // if(!taskDetail) return null;
        this.dataForm1[0].def_value =  taskDetail ? taskDetail.name : "";
        //this.dataForm1[1].def_value = taskDetail ? taskDetail.description : "";
        if(taskDetail) this.dataForm1[1].def_value = moment(taskDetail.deadline).format('DD/MM/YYYY HH:mm A');
        let arrAssignTo = taskDetail && taskDetail.assignTo && Config.isJSON(taskDetail.assignTo) ? JSON.parse(taskDetail.assignTo) : [];
        let attachments = taskDetail && taskDetail.attachments && Config.isJSON(taskDetail.attachments) ? JSON.parse(taskDetail.attachments) : [];
        return (
            <div className="my-team my-team-detail create-task task-detail row col-sm-12 full_h_1">
                <div className="col-sm-12 full_w display_col padding-0 full_h_1">
                    <div className="member-view display_col ">
                        <div className="display_row align-center back-to-team"
                             style={{marginTop: 0}}
                             onClick={() => {
                                 browserHistory.push('/tasks')
                             }}><img
                            src={require('../../assets/images/roster/icon-back-def.png')} alt={""}/>Back to
                            Tasks
                        </div>
                        <div className="display_row col-sm-12 align-between flex_wrap scroll">
                            <div className="form-column">
                                <div className="txt-condensed-extra-bold full_w text-center" style={{
                                    fontSize: 19,
                                    lineHeight: '27px',
                                    marginBottom: 20,
                                    width: '100%',
                                    textAlign: 'left'
                                }}>TASK DETAILS
                                </div>
                                <CForm isTaskView={true} data={this.dataForm1} ref="CForm"
                                       styleForm="col-sm-12 form-create-task"/>
                            </div>
                            <div className="form-column">
                                <div className="txt-condensed-extra-bold full_w text-center" style={{
                                    fontSize: 19,
                                    lineHeight: '27px',
                                    marginBottom: 20,
                                    width: '100%',
                                    textAlign: 'left'
                                }}>INSTRUCTIONS
                                </div>
                                <div className="txt-note-task col-sm-12 background-grey" rows="10" cols="100">
                                    <div className="task-description" style={{"height" : "100%", "width" : "100%", "padding": "15px"}}>
                                        {taskDetail ? taskDetail.note : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="form-column">
                                <div className="txt-condensed-extra-bold full_w text-center" style={{
                                    fontSize: 19,
                                    lineHeight: '27px',
                                    marginBottom: 20,
                                    width: '100%',
                                    textAlign: 'left'
                                }}>ASSIGN STAFF
                                </div>
                                <div className="display_row flex_wrap">
                                {arrAssignTo && arrAssignTo.length > 0 && arrAssignTo.map((key, idx)=>{
                                    return(
                                        <div className="staff-view" key={'assignTo'+idx}>
                                            <Image className="avatar-staff" src={(key.user.avatar) ? key.user.avatar : Config.avaDef} style={{width:'100%', height:'100%'}}/>
                                            <span className="name-staff">{key.name}</span>
                                        </div>
                                    )
                                })}
                                </div>
                            </div>
                            <div className="form-column">
                                <div className="txt-condensed-extra-bold full_w text-center" style={{
                                    fontSize: 19,
                                    lineHeight: '27px',
                                    marginBottom: 20,
                                    width: '100%',
                                    textAlign: 'left'
                                }}>ATTACHMENTS
                                </div>
                                <div className="col-sm-8 padding-0 attach display_row flex_wrap full_w">
                                    {attachments && attachments.length > 0 && attachments.map((key, idx) => {
                                        // let isEmptyAttachmentFiles = !Object.values(attachmentFiles).some(x => (x !== null && x !== ''));
                                        // let renderKey = isEmptyAttachmentFiles || !attachmentFiles[key] ? key : `${attachmentFiles[key].content}`;
                                        // let type = isEmptyAttachmentFiles || !attachmentFiles[key] ? key.split('.')[attachments.length - 1] : attachmentFiles[key].type;


                                        let renderKey = key.src; //: `${attachmentFiles[key].content}`;
                                        if(key.fd){
                                        let type = key.fd.split('.').pop();

                                        let item = this.renderIcon(renderKey, type);
                                        return (
                                            <div className="imgPreview" key={"taskImage" + idx}>
                                                {item}
                                            </div>
                                        )
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default connect(state => ({
        checkLogin : state.main.checkLogin,
        teamList: state.team.teamList,
        memberList: state.team.memberList,
        teamId: state.team.teamId
    }),
    (dispatch) => ({
        mainActions: bindActionCreators(mainActions, dispatch),
        taskActions: bindActionCreators(taskActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    })
)( TasksDetailPage);