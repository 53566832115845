// action types
export const types = {
    ROSTER_LIST: 'ROSTER_LIST',
    ROSTER_LIST_SUCCESS: 'ROSTER_LIST_SUCCESS',
    ROSTER_ADD: 'ROSTER_ADD',
    ROSTER_ADD_SUCCESS: 'ROSTER_ADD_SUCCESS',
    ROSTER_DELETE: 'ROSTER_DELETE',
    ROSTER_EDIT: 'ROSTER_EDIT',
    ROSTER_EDIT_SUCCESS: 'ROSTER_EDIT_SUCCESS',
    GET_ROSTER_CLOCK_LIST: 'GET_ROSTER_CLOCK_LIST'
};

export function getListRoster(params,cb) {
    return{
        type: types.ROSTER_LIST,
        params,
        cb
    }
}

export function addRoster(params,cb) {
    return{
        type: types.ROSTER_ADD,
        params,
        cb
    }
}

export function removeRoster(params,cb) {
    return{
        type: types.ROSTER_DELETE,
        params,
        cb
    }
}

export function editRoster(params,cb) {
    return{
        type: types.ROSTER_EDIT,
        params,
        cb
    }
}

export function getRosterClock(params, cb) {
    return{
        type: types.GET_ROSTER_CLOCK_LIST,
        params,
        cb
    }
}