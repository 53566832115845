/**
 * @copyright 2017 @ Tech Seed Labs
 * @author rocachien
 * @create 2017/08/12 10:37
 * @update 2017/08/12 10:37
 * @file src/container/index.js
 */

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import { Image } from 'react-bootstrap';
import * as mainActions from '../redux/main/main_actions';
import * as deviceActions from '../redux/device/device_actions';
import * as messageActions from '../redux/message/message_actions';
import {connect} from 'react-redux';

import Popup from '../components/libs/popup/popup';
import Config from '../config';
import MenuLeft from '../components/menu-left/menu-left';
import Header from '../components/header/header';
import Login from '../components/login/login';
import Home from '../components/home/home';
import Register from '../components/register/register';
import RegisterLink from '../components/register/register-link';
import RegisterPayment from '../components/register/register-payment';
import OnBoardingEmail from '../components/on-boarding/on-boarding-email';
import OnBoardingOne from '../components/on-boarding/on-boarding-one';
import OnBoardingThree from '../components/on-boarding/on-boarding-three';
import OnBoardingTwo from '../components/on-boarding/on-boarding-two';
import CreatePass from '../components/on-boarding/create-pasword';
import EmailWellcome from '../components/on-boarding/email-welcome';
import * as Firebase from "../components/messages/push-notification";
import ForgetPassword from '../components/forget-password/forgetPassword';
import ResetPassword from '../components/forget-password/resetPassword';
import Loader from '../components/loader/loader';

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            admin: null,
            loading: true,
            checkLoc: null,
            checkLoginScreen: false
        };
    }

    getDevice = () => {
        if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
            return 'Opera';
        } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
            return 'Chrome';
        } else if (navigator.userAgent.indexOf("Safari") !== -1) {
            return 'Safari';
        } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
            return 'Firefox';
        } else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) {
            return 'IE';
        } else {
            return 'unknown';
        }
    };

    isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    } 

    componentDidMount() {
        this.loading((err, data) => {
            if (data) {
                Config.loadSocket();
            }
        });
        this._checkLocation();
        let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;
        if (login) {
            Firebase.fbInitializeApp();

            Firebase.fbRequestPermission((error, token) => {
                if (token) {
                    Config.deviceToken = token;
                    console.log('=== fbRequestPermission -> token: ', token);
                    let params = {
                        md: this.getDevice(),
                        token: token,
                        os: 'WEB'
                    };
                    this.props.deviceActions.addDevice(params);
                }
            });

            if (this.isMobileDevice()) {
                this.setState({ showRedirect: true });
                return <OnRedirect />;
            }

            this.props.mainActions.getNumberBadge((err, data) => {
                console.log("getNumberbadge", err, data);
                if (data && data.badge) {
                    this.props.mainActions.updateNumberBadge(data.badge, (err, data) => {
                        console.log("updateNumberBadge", err, data);
                    });
                } else {
                    console.log('Data get number badge is error')
                }
            });

            Firebase.fbOnMessage((error, payload) => {
                if (payload !== undefined && payload!==null) {

                    console.log('=== fbOnMessage -> payload: ', payload);
                    console.log('=== fbOnMessage -> payload: ', payload.data);
                    console.log('=== fbOnMessage -> payload: ', payload.data["gcm.notification.type"]);
                    console.log(payload.data["gcm.notification.type"] === 'LOGOUT');
                    if (payload.data["gcm.notification.type"] === 'LOGOUT') {
                        this.props.mainActions.checkOutNoUrl((err, data) => {
                            window.location.reload();
                        });
                    } else {
                        /*this.props.mainActions.updateNumberBadge(payload.data["gcm.notification.badge"], (err, data) => {
                            console.log("updateNumberBadge", err, data);
                        });*/

                        this.props.mainActions.getNumberBadge((err, data) => {
                            console.log("getNumberbadge", err, data);
                            if (data && data.badge) {
                                this.props.mainActions.updateNumberBadge(data.badge, (err, data) => {
                                    console.log("updateNumberBadge", err, data);
                                });
                            } else {
                                console.log('Data get number badge is error')
                            }
                        });
                    }

                } else{
                    console.log("=== error -> payload is empty === ");
                }
            });
        }

        if (window.location.href.indexOf('messages') !== -1 && this.props.messageRoom && this.props.messageRoom.id) {
            console.log('====== onLeaveRoom ======', this.props.messageRoom.id);
            this.onLeaveRoom(() => {
                this.props.messageActions.messageRoom(null);
            });
        }

    }

    onLeaveRoom = (cb) => {
        if (!Config.socket || Config.socket === null) {
            return false;
        }

        Config.socket.emit('put',
            {
                url: '/api/message/leave',
                data: {room: this.props.messageRoom.id}
            }, (data) => {
                console.log("leave room=================", data);
                cb && cb();
            });
    };

    loading = (cb) => {
        // console.log("check loading");
        this.props.mainActions.loading((err, data) => {
            this.setState({
                loading: false
            });
            cb && cb(err, data);
        });
    };

    onMenu = (data) => {

        if (data.route === "/logout") {
            this.props.loginActions.logout();
        }

        this.setState({route: data.route});
    };

    _onLogout = () => {
        this.props.mainActions.checkOut((err, data) => {
            window.location.reload();
        });
    };

    _checkLocation() {
        // let arr = ['/create-password','/welcome','/home','/register-company','/register-payment','/on-boarding-email', '/login','/on-boarding-one','/on-boarding-two','/on-boarding-three'];
        let arr = [
            '/create-password',
            '/home',
            '/welcome-page',
            '/register-company',
            '/register-payment',
            '/register-link',
            '/on-boarding-email',
            '/login',
            '/on-boarding-one',
            '/on-boarding-two',
            '/on-boarding-three',
            '/forget-password',
            '/reset-password',
            '/customer/payment/cancel',
            '/customer/payment/success'
        ];
        console.log('---> log location: ', window.location.href)
        let checkLoc = arr.filter(i => {
            return (window.location.href.indexOf(i) !== -1)
        });
        if (checkLoc && checkLoc.length > 0) {
            this.setState({
                checkLoc: true
            })
        }
    }

    render() {
        const {children} = this.props;

        let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;

        // console.log(login);

        let href = window.location.href;

        return (
            <div>{(this.state.showRedirect && login) ? (
                <OnRedirect />
            ) : (
            <div className="display_col full_w">
                {this.state.loading && (
                    <div className="full_w full_h">
                        {/* <Header type={0} login={null}/> */}
                        <Loader/>
                    </div>
                )}
                {!this.state.loading && !login && !this.state.checkLoc &&
                <div className="display_col">
                    <div className="login-container">
                        <Login/>
                    </div>
                </div>
                }
                {!this.state.loading && this.state.checkLoc &&
                <div className="display_col background-image-full">
                    <div className="login-container">
                        {href.indexOf('/home') !== -1 && <Home/>}
                        {href.indexOf('/login') !== -1 && <Login/>}
                        {href.indexOf('/register-company') !== -1 && <Register/>}
                        {href.indexOf('/register-link') !== -1 && <RegisterLink/>}
                        {href.indexOf('/register-payment') !== -1 && <RegisterPayment/>}
                        {href.indexOf('/on-boarding-email') !== -1 && <OnBoardingEmail/>}
                        {href.indexOf('/customer/payment/cancel') !== -1 && <OnBoardingEmail/>}
                        {href.indexOf('/customer/payment/success') !== -1 && <OnBoardingEmail/>}
                        {href.indexOf('/on-boarding-one') !== -1 && <OnBoardingOne/>}
                        {href.indexOf('/on-boarding-two') !== -1 && <OnBoardingTwo/>}
                        {href.indexOf('/on-boarding-three') !== -1 && <OnBoardingThree/>}
                        {href.indexOf('/welcome-page') !== -1 && <EmailWellcome/>}
                        {href.indexOf('/create-password') !== -1 && <CreatePass/>}
                        {href.indexOf('/forget-password') !== -1 && <ForgetPassword/>}
                        {href.indexOf('/reset-password') !== -1 && <ResetPassword/>}
                    </div>
                </div>
                }
                {!this.state.loading && login && !this.state.checkLoc &&
                <div className="display_col full_w full_h">
                    {this.props.team_pending &&
                    <Loader/> 
                    }
                    <div className="display_col full_w full_h" style={{overflow: 'hidden'}}>
                        <Header onLogout={this._onLogout} type={1} login={login}/>
                        <div className="display_row align-between full_h full_w" style={{paddingTop: 60}}>
                            <MenuLeft onMenu={this.onMenu}/>
                            <div className='display_col main'>
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
                }

                <Popup ref={(ref) => Config.popup = ref}/>
                </div>)}
            </div>
        );
    }
}


class OnRedirect extends Component {
    render() {
        return (
                <div className="reg-row" style={{padding:'20px 20px'}} >
                            <div className='onboarding'>
                        <div className='onboarding-logo'>
                        <Image
                    className="logo"
                    src={require("../assets/images/mobile-responsive-icon.png")}
                    />
                </div>
                   <div className="col-sm-12" style={{padding:'15px 10px 20px'}}>
                        <div className="txt-header"
                             style={{marginBottom:15}}>
                            Thank you and Welcome!
                        </div>
                        <p className="text-center"
                            style={{margin:'20px 0 0'}}>
                            Thank you for signing up to and trying Teemie. As part of your subscription you will have two ways to access the platform. 
                        </p>
                        <div className='mob-logo sm-row'>
                        <Image className="mob-lap col-6"
                            src={require("../assets/images/imac-pro-space-grey.png")}
                            />
                        <Image className="mob-mobile col-6"
                            src={require("../assets/images/iphone-13-pro.png")}
                            />
                        </div>
                        <div className='note-change'>   
                        <p className="text-center" style={{margin:'20px 0 0',}}>
                            Our web app which is for management only and gives you a Teemie dashboard on your laptop/desktop to create, manage and edit to the fullest.</p>
                        <p className="text-center" style={{margin:'20px 0 0',}}>
                            Our specialist iOS and Android apps which are for both management and staff. So you can manage on the go.</p>
                        <p className="text-center" style={{margin:'20px 0 0',}}>
                            When initially building your team, we recommend continuing on the web app, or downloading the mobile app.
                        </p>
                        </div> 
                   </div> 
               </div>       
            </div>
        );
    }
}

export default connect(state => ({
        checkLogin: state.main.checkLogin,
        messageNew: state.message.messageNew,
        team_pending:state.team.team_pending,
        task_pending:state.task.task_pending
    }),
    (dispatch) => ({
        mainActions: bindActionCreators(mainActions, dispatch),
        deviceActions: bindActionCreators(deviceActions, dispatch),
        messageActions: bindActionCreators(messageActions, dispatch),
    })
)(Index);
