/**
 * @copyright 2017 @ Tech Seed Labs
 * @author rocachien
 * @create 2017/08/12 10:37
 * @update 2017/08/12 10:37
 * @file src/index.js
 */

const cacheBuster = new Date().getTime(); // Replace with your cache buster

import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes/routes.js?v=${cacheBuster}';
import 'bootstrap/dist/css/bootstrap.css?v=${cacheBuster}';
import 'bootstrap/dist/css/bootstrap-theme.css?v=${cacheBuster}';
import './style/index.css?v=${cacheBuster}';
import '../node_modules/font-awesome/css/font-awesome.min.css?v=${cacheBuster}';
import { datadogRum } from '@datadog/browser-rum';

function render(Component) {
    ReactDOM.render(
        (
            <Component/>
        ),
        document.getElementById('adminRoot')
    );
}

console.log('this is v2')
render(Routes);

if (module.hot) {
    module.hot.accept('./routes/routes.js', () => {
        const NextApp = require('./routes/routes.js').default;
        render(NextApp);
    });
}


let checkLogin = localStorage.getItem('REFRESHMENT');
let forms ={
  Name:'Teemie'
};
if(!checkLogin){
    localStorage.setItem('REFRESHMENT',JSON.stringify(forms));
    setTimeout(function(){
     window.location.reload();    
    },500) 
}else{
    localStorage.removeItem('REFRESHMENT');
}

import { loadStripe } from '@stripe/stripe-js';

export const stripePromise = loadStripe('pk_test_brt78cMyIzgRGP4jNKl0Xylv');

// Set user information when the user logs in or when you have access to the user's details
let checkLoginUser = localStorage.getItem('LOGIN');
checkLogin = checkLoginUser ? JSON.parse(checkLoginUser) : null;
let user = checkLogin ? checkLogin.user.id : null;
let userName = checkLogin ? checkLogin.user.fullName : null;
let userEmail = checkLogin ? checkLogin.user.email : null

datadogRum.setUser({
    id: user,
    name:userName,
    email: userEmail,
});

datadogRum.init({
    applicationId: '8ca7428b-61e7-47ab-8923-b562519e49aa',
    clientToken: 'pub2a29954232b9b88edd33d4dc7d19255d',
    site: 'datadoghq.eu',
    service: 'teemie-web-prod',
    env: 'Production',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0', 
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackErrors: true,
    defaultPrivacyLevel: 'mask-user-input',
});