
import React,{Component} from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';

//import action
import * as userActions from "../../redux/user/user_actions";
import * as companyActions from "../../redux/company/company_actions";
import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
import * as eventActions from "../../redux/event/event_actions";

//import css
import '../register/register.css'
import './calendar.css'
import '../my-team/my-team.css'
import '../my-team-detail/my-team-detail.css'
import '../libs/date-time-picker/date-time-picker.css';

//import component
import {Button, Image, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';
import SearchBar from "../my-team-detail/search-bar";
import CForm from "../libs/cform/cform";

//import lib react day
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

//import lib react select
import Select from 'react-select';

import moment from "moment";
import Config from "../../config";
import ReactLoading from "react-loading";

class Calendar extends Component{
    constructor(props){
        super(props);
        this.state={
            idTeam: null,
            flagTab: 1,
            isCreateEvent: false,
            isShowEvent: false,
            listEvent: [],
            month:null,
            fixedValue: 70
        };
        this.dataFilter = {
            limit: 1000,
            skip: 0,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillMount() {
        // window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let scrollTop = window.pageYOffset;
        if(scrollTop > 5) {
            this.setState({
                fixedValue: 60
            });
        } else {
            this.setState({
                fixedValue: 70
            });
        }
    }

    _getMemberTeamList = () => {
        this.props.teamActions.getMemberTeamList({team: this.state.idTeam},(err, data)=> {
            if(err){
                Config.popup.show('INFO',err.message);
            }
            else{
                // that._goScreenTeamDetai();
            }
        });
    };

    onChange = date => this.setState({ date });

    changeTab = (idx) => {
      this.setState({
          flagTab: idx
      },()=>{
          this.getEvent();
      })
    };

    changeDropDownTeam = (data) => {
        this.setState({
            idTeam: data.id
        },()=>{
            this.getEvent(data.id);
        });
    };

    getId = () => {
        let location = window.location.href;
        if(location.indexOf('?user=') != -1){
            let memberId = window.location.search.split('?user=');
            memberId=memberId[memberId.length-1].split("&eventId=");
            memberId=memberId[0];
            memberId = memberId || null;
            return memberId;
        }
        else{
            return null;
        }
    };

    getEventDate = (eventList) => {
        let location = window.location.href;
        if(location.indexOf('?eventId=') != -1){
            let eventId = window.location.search.split('eventId=');
            eventId = eventId[eventId.length-1].split('&tid=');
            eventId=eventId[0];
            let eventDate = eventList ? eventList.find(n => n.id == eventId).time : null;
            return eventDate;
        }
        else{
            return false;
        }   
    }

    getEvent = (idTeam, time) => {
        let type= this.state.flagTab === 1 ? 'year' : 'month';
        let timeUse = time ? time : this.state.month;
        let begin = timeUse ? moment(timeUse).startOf(type).toISOString() : moment().startOf(type).toISOString();
        let end = timeUse ? moment(timeUse).endOf(type).toISOString() : moment().endOf(type).toISOString();
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        let uid = checkLogin.user.id;
        this.dataFilter.uid = uid;
        this.dataFilter.team = this.state.idTeam;// ? id : this.state.idTeam;
        this.dataFilter.timeFrom = begin;
        this.dataFilter.timeTo= end;

        let MemberId = this.getId();
        if(MemberId){
            this.dataFilter.user = MemberId;
            this.props.eventActions.summaryEvent(this.dataFilter, (err, data)=>{
                if(err){
                    // Config.popup.show('INFO',[Config.alertMess(err)]);
                    this.setState({
                        listEvent: [],
                    })
                }
                else if(data){
                    this.setState({
                        listEvent: data,
                    })
                }
            })
        }
        else{
            this.props.eventActions.getListEvent(this.dataFilter, (err, data)=>{
                if(err){
                    // Config.popup.show('INFO',[Config.alertMess(err)]);
                    this.setState({
                        listEvent: [],
                    })
                }
                else if(data){
                    let showEventDate = this.getEventDate(data);
                    this.setState({
                        listEvent: data,
                        isShowEvent: showEventDate
                    })
                }
            })
        }
    };

    toggleCreateEvent = () => {
        this.setState({
            isCreateEvent: !this.state.isCreateEvent
        })
    };

    toggleShowEvent = (date) => {
        this.setState({
            isShowEvent: date ? date : false
        })
    };

    setDataListTeam = (data,id) => {
        this.setState({
            idTeam: this.state.idTeam ? this.state.idTeam : (id ? id : null)
        },()=>{
            this._getMemberTeamList();
            this.getEvent();
        });
    };

    addEvent = (params, cb) => {
        this.props.eventActions.addEvent(params, (err, data)=>{
            if(data){
                this.getEvent();
                this.setState({
                    isCreateEvent: false
                })
            }
            cb && cb(err, data);
        })
    };

    goEvent = (date) => {
        this.toggleShowEvent(date);
    };

    onChangeMonth = (time) => {
        this.setState({
            month: time
        },()=>{
            this.getEvent(null, time);
        });
    };

    render(){
        let {teamList,memberList} = this.props;
        let dataTab = ['MONTH','YEAR'];
        let myTeam = [];
        if(teamList && this.state.idTeam){
            myTeam = teamList.find(i=>{return (i.id === this.state.idTeam)});
        }
        return(
            <div className="full_w full_h">
                <div className="register calendar-view row my-team-detail my-team height-100vh">
                    <div style={{position:'relative', minHeight:'100%'}} className="full_w full_h_1">
                        <div className="col-sm-12 display_col padding-0 full_h_1" style={{position:'relative'}}>
                            <div className="search-bar col-sm-12 shadow-1 display_row align-center sub-header-fixed" style={{top:this.state.fixedValue}}>
                                <SearchBar isSearch={true}
                                           teamList={teamList}
                                           myTeam={myTeam}
                                           dataListTeam={this.setDataListTeam}
                                           changeDropDownTeam={this.changeDropDownTeam}/>
                                <div className="search-view" style={{flexWrap:'wrap-reverse', justifyContent:'flex-end'}}>
                                    <div className="col-sm-6 display_row tab-view">
                                        {dataTab.map((key, idx)=>{
                                            return(
                                                <div key={idx} className={"tab-item "+(this.state.flagTab === idx ? 'active' : '')}
                                                     onClick={()=>this.changeTab(idx)}>
                                                    {key}
                                                </div>
                                            )
                                        })}
                                    </div>
                                <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={<Tooltip id="edit">Create New Event</Tooltip>} >
                                    <div className="display_row align-center btn-new-event button-resp"
                                        onClick={this.toggleCreateEvent}>
                                        <Image src={require('../../assets/images/icon-plus.png')}
                                            className="icon-plus"/>
                                    </div>
                                </OverlayTrigger>
                                <div className="display_row align-center btn-new-event button-not-resp"
                                     onClick={this.toggleCreateEvent}>
                                    <Image src={require('../../assets/images/icon-plus.png')}
                                           className="icon-plus"/>
                                    Create New Event
                                </div>
                                </div>
                            </div>
                            <div className="member-view display_col scroll edit-calender">
                                {this.state.flagTab === 1 && (
                                    <YearView goEvent={this.goEvent}
                                              listEvent={this.state.listEvent}/>
                                )}
                                {this.state.flagTab === 0 && (
                                    <MonthView goEvent={this.goEvent}
                                               onChangeMonth={this.onChangeMonth}
                                               listEvent={this.state.listEvent}/>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <CreateEvent show={this.state.isCreateEvent}
                             idTeam={this.state.idTeam}
                             dataStaff={memberList}
                             addEvent={this.addEvent}
                             handleClose={()=>this.toggleCreateEvent()}/>

                <ShowEvent show={this.state.isShowEvent}
                           listEvent={this.state.listEvent}
                            handleClose={this.toggleShowEvent}/>
            </div>
        )
    }
}

class CreateEvent extends Component {

    constructor(props){
        super(props);
        this.dataForm = [
            {
                type: 'TEXT',
                key: "name",
                id: "name",
                placeholder:"Event Name",
                feedback: false,
                def_value: '',
                // def_value:'TrangHoang',
                validator: 'isName',
            },
            {
                type: 'DATE',
                key: "day",
                id: "day",
                alignPopover:'bottom',
                placeholder:"Event Day",
                validator: 'isRequired',
                // def_value: '',
                def_value:moment().valueOf(),
                feedback: false,
            },
            {
                type: 'TIME',
                key: "time",
                id: "time",
                class:"rc-time-picker-input",
                alignPopover:'bottom',
                placeholder:"Start At",
                feedback: false,
                // def_value: '',
                def_value:moment().format('HH:mm'),
                validator: 'isRequired',
            }
        ];
        this.state = {
            dataChoose:[],
            isLoading: false
        };
        this.isSubmit=false;
    }

    _handleClose = () => {
        this.setState({
            dataChoose:[]
        });
        if(this.props.handleClose){
            this.props.handleClose();
        }
    };

    onChangeSelect = (e, idx) => {
        let {dataStaff} = this.props;
        let dataChoose = [];
        for(let eChild in e){
            let dataTeam = dataStaff.filter(i=>i.id === e[eChild].value);
            dataChoose=dataChoose.concat(dataTeam);
        }
        this.setState({
            dataChoose:dataChoose
        })
    };

    _addEvent = () => {
        if(this.isSubmit) return;
        this.isSubmit = true;
        this.setState({
            isLoading: true
        });
        let check1 = this.refs['CForm'].formValidate();
        let dt = this.refs['CForm'].formValue();

        if(!check1) {
            this.setState({messageEvent: 'Fill out all fields or input correct details to the fields and try again'});
            setTimeout(()=>{
                this.setState({messageEvent: '',isLoading:false});
                this.isSubmit = false;
            },5000);
            return;
        }
        else if(this.state.dataChoose.length === 0){
            this.setState({messageEvent: 'Please select at least one staff'});
            setTimeout(()=>{
                this.setState({messageEvent: '',isLoading:false});
                this.isSubmit = false;
            },5000);
            return;
        }

        let dataStaffId = [];
        this.state.dataChoose.filter(i=>{if(i.user && i.user.id) dataStaffId.push(i.user.id); return true;});

        let day = moment(dt.day.value).format('DD/MM/YYYY');
        let time= dt.time.value
        let date = moment(day+' '+time, 'DD/MM/YYYY HH:mm').valueOf();
        console.log("day+' '+time, 'DD/MM/YYYY HH:mm",day+' '+time, 'DD/MM/YYYY HH:mm')
        let isValidTime = moment(day+' '+time, 'DD/MM/YYYY HH:mm').isValid()
        if(!isValidTime){
            this.setState({messageEvent: 'Please select valid time'});
            return
        }
        console.log("<========isValidTime=========>",isValidTime)
        if(moment(day+' '+time, 'DD/MM/YYYY HH:mm')< new Date()){
            this.setState({messageEvent: 'Please select valid time'});
            setTimeout(()=>{
                this.setState({messageEvent: '',isLoading:false});
                this.isSubmit = false;
            },5000);
            return
        }
        let params = {
            team: this.props.idTeam,
            name: dt.name.value,
            time: date,
            staff: JSON.stringify(dataStaffId)
        };

        this.isSubmit = false;

        if (this.props.addEvent) {
            this.props.addEvent(params, (error, data) => {
                if (error) {
                    this.isSubmit = true;
                    this.setState({
                        messageEvent: error.message,
                        isLoading: false
                    });
                    this.isSubmit = false;
                    // setTimeout(()=>{
                    //     this.setState({messageEvent: '',isLoading: false});
                    //     this.isSubmit = false;
                    // },5000);
                }
                else {
                    this.setState({ isLoading: false, dataChoose: [] });
                    // this._handleClose();
                }
            });
        }
    };

    ValueContainer = ({ innerProps,selectProps,data}) =>{
        let dataChoose = this.state.dataChoose.filter(i=>i.id===data.value);
        dataChoose=dataChoose[0] ? dataChoose[0] : null;
        if(!dataChoose) return null;
        return(
            <div className="staff-view">
                <Image alt="" className="avatar-staff"
                       src={(dataChoose.user.avatar) ? dataChoose.user.avatar : Config.avaDef}
                       style={{width:'100%', height:'100%'}}/>
                <span className="name-staff">{dataChoose.name}</span>
            </div>
        )
    };

    render(){
        let {dataStaff} = this.props;
        let dataOption = [];

        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;

        if(dataStaff) {
            dataStaff.filter(i=>{if(checkLogin.user.id !== i.user.id && i.status === 'ACTIVE'  && i.role!=="OWNER"){dataOption.push({value: i.id, label:i.name})} return true;})
            // dataStaff.filter(i=>{if(checkLogin.user.id !== i.user.id){dataOption.push({value: i.id, label:i.name})} return true;})
        }
        this.dataForm[1].def_value = moment().valueOf();
        this.dataForm[2].def_value = moment().format('HH:mm');

        return(
            <Modal show={this.props.show} onHide={this._handleClose} className="fix-modal event-schedule">
                <Image src={require('../../assets/images/btn-close-popup.png')}
                       className="icon-close"
                       onClick={this._handleClose}/>
                <div className="modal-body">
                    <div className="title">CREATE EVENT</div>
                    <div className="display_row align-between">
                        <CForm data={this.dataForm} ref="CForm"
                               styleForm="form-create-event"/>
                        <div className="drop-staff">
                            <Select
                                options={dataOption}
                                components={{MultiValueLabel:this.ValueContainer}}
                                placeholder='Choose Staff'
                                isMulti={true}
                                className={`open-sans-regular basic-multi-select`}
                                classNamePrefix="select"
                                closeMenuOnSelect={false}
                                onChange={this.onChangeSelect}
                            />
                        </div>
                    </div>

                    <Button className="menu-left-action btn-linear shadow btn-linear-invite"
                            onClick={this._addEvent}>
                        SAVE
                        {this.state.isLoading &&
                        <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                            <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30}/>
                        </div>
                        }
                    </Button>
                    {this.state.messageEvent!=='' && (
                        <div className="register_error" style={{width:'100%', bottom:60, textAlign:'center',alignItems:'flex-end'}}>
                            {this.state.messageEvent}
                        </div>
                    )}
                </div>
            </Modal>
        )
    }
}

class ShowEvent extends Component {

    _handleClose = () => {
        if(this.props.handleClose) this.props.handleClose();
    };

    render(){
        let {listEvent,show} = this.props;
        let dataEventFilter = listEvent.filter(i=>(moment(i.time).startOf('date').valueOf() === moment(show).startOf('date').valueOf()));
        dataEventFilter = dataEventFilter.sort((i,j)=>{return moment(i.time).valueOf() - moment(j.time).valueOf()});
        return(
            <Modal show={show ? true : false} onHide={this._handleClose}
                   style={{width: 450, maxWidth:'100%', margin:'auto'}}>
                <Image src={require('../../assets/images/btn-close-popup.png')}
                       className="icon-close"
                       onClick={this._handleClose}/>
                <Modal.Body>
                    <div className="title">EVENT ON DAY {moment(moment(show).format('LL'), 'LL').format('LL').toUpperCase()}</div>
                        <div className="display_col event-view event-view-popup" style={{maxHeight:'80vh', overflow:'auto'}}>
                            {dataEventFilter && dataEventFilter.map((key, idx)=>{
                                console.log(key)
                                return(
                                    <div className="even-row align-between drop-staff"
                                         style={{height:'auto',minHeight: 142, width:'100%', border:'none'}}
                                         key={"even-row"+idx}>
                                        <span style={{width:'100%', textAlign:'left', display:'block'}}>
                                            {key.name} start at {moment(key.time).format('DD/MM/YYYY H:mm')}
                                        </span>
                                        <div className="display_row list-staff-choose">
                                            {key.staff && key.staff.map((keyStaff, idxStaff)=>{
                                                return(
                                                    <div className="staff-view" key={"staff"+idxStaff}>
                                                        <Image className="avatar-staff" src={keyStaff.avatar ? keyStaff.avatar : Config.avaDef}
                                                               style={{width:'100%', height:'100%'}}/>
                                                        <span className="name-staff">{keyStaff.fullName}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                </Modal.Body>
            </Modal>
        )
    }
}

class YearView extends Component {
    goEvent = (data) => {
        if(!data) return;
        if(this.props.goEvent) this.props.goEvent(data);
    };

    render(){
        let {listEvent} = this.props;
        let dataEventFilter = [];
        dataEventFilter = listEvent.sort((i,j)=>{return moment(i.time).valueOf() - moment(j.time).valueOf()});
        return(
            <div className="col-sm-12 year-view">
                {console.log("Day picker",2021)}
                <DayPicker numberOfMonths={12}
                           canChangeMonth={false}
                           month={new Date(new Date().getFullYear(), 0)}
                           renderDay={(day)=>{
                               let date = day.getDate();
                               let dataEventFilter = listEvent.filter(i=>(moment(i.time).startOf('date').valueOf() === moment(day).startOf('date').valueOf()));
                               let className = (dataEventFilter && dataEventFilter.length>0) ? 'hasEvent' : '';
                               return(
                                   <div className={className} onClick={()=> (dataEventFilter && dataEventFilter.length>0) ? this.goEvent(day) : this.goEvent() }>
                                       {date}
                                   </div>
                               )
                           }}
                           captionElement={({date}) => {
                               return (
                                   <div className="DayPicker-Caption">{moment(date).format('MMM')}</div>
                               )
                           }}
                />
            </div>
        )
    }
}

class MonthView extends Component {

    constructor(props){
        super(props);
        this.state={
            dataEvent: null
        }
    }

    goEvent = (data) => {
        if(this.props.goEvent) this.props.goEvent(data);
    };

    onChangeMonth = (e) => {
        if(this.props.onChangeMonth) this.props.onChangeMonth(e);
    };

    render(){
        let {listEvent} = this.props;
        let dataEventFilter = [];
        dataEventFilter = listEvent.sort((i,j)=>{return moment(i.time).valueOf() - moment(j.time).valueOf()});
        return(
            <div className="col-sm-12 month-view display_row align-around"
                 style={{border:'none', flexWrap:'wrap'}}>
                <DayPicker numberOfMonths={1}
                           canChangeMonth={true}
                           month={new Date()}
                           date={new Date()}
                           showOutsideDays={true}
                           onMonthChange={(e)=>this.onChangeMonth(e)}
                           renderDay={(day)=>{
                               const date = day.getDate();
                               let dataEventFilter = listEvent.filter(i=>(moment(i.time).startOf('date').valueOf() === moment(day).startOf('date').valueOf()));
                               let className = (dataEventFilter && dataEventFilter.length>0) ? 'hasEvent' : '';
                               console.log(dataEventFilter)
                               return(
                                   <div className={className} onClick={()=>(dataEventFilter && dataEventFilter.length>0) ? this.goEvent(day) : this.goEvent()}>
                                       {date}
                                   </div>
                               )
                           }}
                           weekdayElement={({weekday, className, localeUtils, locale})=>{
                               const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
                               return(
                                   <div className={className} title={weekdayName}>
                                       {weekdayName.slice(0, 3).toUpperCase()}
                                   </div>
                               )
                           }}
                           navbarElement={({nextMonth,previousMonth,onPreviousClick,onNextClick,className,localeUtils})=>{
                               return(
                                    <div className={className}>
                                        <i className="fa fa-angle-left" onClick={() => onPreviousClick()}/>
                                        <i className="fa fa-angle-right" onClick={() => onNextClick()}/>
                                    </div>
                                )
                           }}
                />
                <div className="display_col event-view col-md-6">
                    <span className="txt-event">Event</span>
                    {dataEventFilter && dataEventFilter.length <= 0 &&
                        <span className="txt-event no-event">No Event</span>}
                    {dataEventFilter && dataEventFilter.map((key, idx)=>{
                        return(
                            <div className="even-row display_row align-between" key={"even-row"+idx}>
                                <span>{key.name}</span>
                                <span>{moment(key.time).format('DD/MM/YYYY H:mm')}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default connect(state => ({
        checkLogin : state.main.checkLogin,
        teamList: state.team.teamList,
        memberList: state.team.memberList,
        eventList: state.team.eventList
    }),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        eventActions: bindActionCreators(eventActions, dispatch),
    })
)(Calendar);