/**
 * @copyright 2017 @ Tech Seed Labs
 * @author rocachien
 * @create 2017/08/12 10:37
 * @update 2017/08/12 10:37
 * @file src/components/menu-left/menu-left.js
 */
import React from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import {browserHistory} from 'react-router';
import { Image, Modal } from "react-bootstrap";
import * as messageActions from "../../redux/message/message_actions";
import * as teamActions from "../../redux/team/team_actions";
import Config from "../../config";

class MenuLeft extends React.PureComponent{

    constructor(props){
        super(props);

        const location = window.location;

        this.state = {
            route: (location.pathname && location.pathname.length > 3) ? location.pathname : '/my-team',
            infoMember:null,
            isRemoveMember: false,
        };
    }

    componentDidMount = () => {
        let {teamList} = this.props;
        if(!teamList){
            this.props.teamActions.getTeamList();
        }
    };

    onMenu=(data)=>{
        let {teamList} = this.props;
        if(!teamList) return;
        let arrUseTeam = ['MESSAGES','ROSTER','TASKS','CALENDAR'];
        if(arrUseTeam.indexOf(data.id) !== -1 && teamList && teamList.length === 0){
            // Config.popup.show('INFO',[Config.alertMess({code:'NOTTEAM'})]);
            this.setState({ isRemoveMember: true });
            return;
        }
        if(data.id !== 'MESSAGES' && this.props.messageRoom && this.props.messageRoom.id){
            console.log('====== onLeaveRoom ======',this.props.messageRoom.id);
            this.onLeaveRoom(()=>{
                this.props.messageActions.messageRoom(null);
            });
        }
        this.setState({route: data.route});
        browserHistory.push(data.route);
    };

    onLeaveRoom = (cb) => {
        if(!Config.socket || Config.socket === null) {
            return false;
        }

        Config.socket.emit('put',
            {
                url: '/api/message/leave',
                data: {room: this.props.messageRoom.id}
            },()=>{
                cb && cb();
            });
    };

    closeModal = () => {
        this.setState({ isRemoveMember: false });
      };
    
    render() {
        let dataMenu = [
            // {
            //     id:'MYACCOUNT',
            //     name:'My Account',
            //     route:'/my-account',
            //     icon:require('../../assets/images/side-menu/my-team-icon.png')
            // },
            {
                id:'MYTEAM',
                name:'My Teams',
                route:'/my-team',
                icon:require('../../assets/images/side-menu/my-team-icon.png')
            },
            {
                id:'MESSAGES',
                name:'Messages',
                route:'/messages',
                icon:require('../../assets/images/side-menu/message-icon.png')
            },
            {
                id:'ROSTER',
                name:'Roster',
                route:'/roster',
                icon:require('../../assets/images/side-menu/roster-icon.png')
            },
            {
                id:'TASKS',
                name:'Tasks',
                route: '/tasks',
                icon:require('../../assets/images/side-menu/task-icon.png')
            },
            {
                id:'CALENDAR',
                name:'Calendar',
                route:'/calendar',
                icon:require('../../assets/images/side-menu/calendar.png')
            },
            {
                id:'ANNOUNCEMENTS',
                name:'Announcements',
                route:'/announcements',
                icon:require('../../assets/images/side-menu/announcement-icon.png')
            },
            {
                id:'WELCOME',
                name:'Welcome',
                route:'/welcome-manager',
                icon:require('../../assets/images/side-menu/welcome-icon.png')
            },           
        ];

        let checkLogin = localStorage.getItem('LOGIN');
        let AdminLogin = false
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        if(this.props.teamList){
            this.props.teamList.map((dt)=>{
                if(dt.members && dt.members.admins){
                    dt.members.admins.map((_dt)=>{
                        console.log("=============>",_dt.userID)
                        if(checkLogin.user.id === _dt.userID){
                            AdminLogin = true
                        }
                    })
                }
            })
        }
        let showWelcome = checkLogin && checkLogin.company && checkLogin.user && checkLogin.company.owner === checkLogin.user.id || AdminLogin
        console.log(this.state.checkLogin)
        console.log("AdminLogin",AdminLogin)
        return(
            <div className="menu-left-container display_col full_h">
                <div className="menu-left-title">MENU</div>
                {dataMenu.map((key, idx)=>{
                    if(key.id === 'WELCOME' && !showWelcome) return null;
                    return(
                        <div key={idx} className={(this.state.route === key.route) ? 'menu-left-action btn-linear-orange' : 'menu-left-no-action'}
                             style={{cursor:'pointer'}}
                             onClick={()=>this.onMenu(key)} >
                            <label className="label-menu-left">
                                {key.name}
                            </label>
                            <img alt="" src={key.icon} className="icon-menu-left"/>
                        </div>
                    )
                })}
                <div>
                <Modal
                    show={this.state.isRemoveMember}
                    onHide={this.closeModal}
                    className="fix-modal remove-user"
                    >
                    <Modal.Body>            
                        <Image
                        className="logo"
                        src={require("../../assets/images/icon-member.png")}
                        />            
                        <div className="content" style={{ margin: "40px 15px 30px ",fontSize:"18px" }}>
                        To access this feature please ensure you have at least one active team            
                        </div>
                    </Modal.Body>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
        messageRoom: state.message.messageRoom,
        teamList: state.team.teamList,
        teamId: state.team.teamId
    }),
    (dispatch) => ({
        messageActions: bindActionCreators(messageActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
    })
)(MenuLeft);