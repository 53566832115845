import {delay} from 'redux-saga';
import {put, takeLatest, take, cancel} from 'redux-saga/effects';
import {types} from "./roster_actions";
import Api from '../../services/api';

export function* getListRoster(data) {
    try {
        yield delay(300);
        let roster = yield Api.put('/roster/search', data.params);
        if(roster && roster.data){
            yield put({type: types.ROSTER_LIST + '_SUCCESS', payload: roster.data});
            data.cb && data.cb(null, roster.data);
        }
        else{
            yield put({type: types.ROSTER_LIST + '_SUCCESS', payload: []});
            data.cb && data.cb(roster, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetListRoster() {
    while (true){
        const watcher = yield takeLatest(types.ROSTER_LIST,getListRoster);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
export function* addRoster(data) {
    try {
        yield delay(300);
        let roster = yield Api.put('/roster/add', data.params);
        if(roster && roster.data){
            yield put({type: types.ROSTER_ADD + '_SUCCESS', payload: roster.data});
            data.cb && data.cb(null, roster.data);
        }
        else{
            data.cb && data.cb(roster, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchAddRoster() {
    while (true){
        const watcher = yield takeLatest(types.ROSTER_ADD,addRoster);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
export function* removeRoster(data) {
    try {
        yield delay(300);
        let roster = yield Api.put('/roster/del', data.params);
        if(roster && roster.data){
            yield put({type: types.ROSTER_DELETE + '_SUCCESS', payload: roster.data});
            data.cb && data.cb(null, roster.data);
        }
        else{
            data.cb && data.cb(roster, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchRemoveRoster() {
    while (true){
        const watcher = yield takeLatest(types.ROSTER_DELETE,removeRoster);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
export function* editRoster(data) {
    try {
        yield delay(300);
        let roster = yield Api.put('/roster/edit', data.params);
        if(roster && roster.data){
            yield put({type: types.ROSTER_EDIT+ '_SUCCESS', payload: roster.data});
            data.cb && data.cb(null, roster.data);
        }
        else{
            data.cb && data.cb(roster, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchEditRoster() {
    while (true){
        const watcher = yield takeLatest(types.ROSTER_EDIT,editRoster);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* getRosterClock(data) {
    try {
         yield delay(300);
         let clockList = yield Api.get(`/roster/clock-records?id=${data.params}`);
        if(clockList && clockList.data){
            data.cb && data.cb(null, clockList.data);
        }
        else{
            data.cb && data.cb(clockList, null);
        }
    }
    catch (e) {
        //data.cb && data.cb(null,null);
    }
}

export function* watchGetRosterClock() {
    while (true){
        const watcher = yield takeLatest(types.GET_ROSTER_CLOCK_LIST, getRosterClock);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
