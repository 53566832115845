import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//import action
import * as userActions from "../../redux/user/user_actions";
import * as companyActions from "../../redux/company/company_actions";
import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
import * as eventActions from "../../redux/event/event_actions";
import * as shiftActions from "../../redux/shift/shift_actions";
import * as rosterActions from "../../redux/roster/roster_actions";

//import css
import '../register/register.css'
import './roster.css'
import '../my-team/my-team.css'
import '../my-team-detail/my-team-detail.css'
import '../libs/date-time-picker/date-time-picker.css';

//import component
import { Button, Image, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SearchBar from "../my-team-detail/search-bar";
import { browserHistory } from "react-router";
import Carousel from 'nuka-carousel';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import moment from "moment";
import Config from "../../config";
import PrintButton from "./PrintButton";
import ReactLoading from "react-loading";
import { CSVLink } from "react-csv";
import Api from '../../services/api.js';
import CForm from "../libs/cform/cform";

import EllipsisText from "react-ellipsis-text";
import { RangeStepInput } from 'react-range-step-input';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Circle } from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";

class Roster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idTeam: null,
            week: 0,
            flagTab: 1,
            flagCreate: false,
            dataInfo: null,
            flagConfirm: false,
            flagActivateClock: false,
            dataCreateStaff: [],
            shiftList: [],
            rosterList: [],
            memberList: null,
            onlyDay: moment().format(),
            arrMemberCreate: null,
            isLoadingExport: false,
            arrDay: [],
            fixedValue: 70,
            headerRosterScroll: false,
            isClockEnabled: false,
            address: '',
            radius: 10,
            lat: 0,
            lng: 0,
            clockList: [],
            isRemoveMember: false,
            isCreateNewRosterShift:false,
            isClockedOut: false,
            isloading: true,
        };
        this.dataFilter = {
            limit: 1000,
            skip: 0,
        };

    }

    componentDidMount() {
        let teamId = window.location.search.split('?tid=');
        teamId = teamId && teamId.length > 1 ? teamId[1] : null;
        this.props.teamActions.saveTeam(teamId, () => {
            this.getArrDay();
        });
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillMount() {
        // window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let scrollTop = window.pageYOffset;
        if (scrollTop > 5) {
            this.setState({
                fixedValue: 60,
                headerRosterScroll: true
            });
        } else {
            this.setState({
                fixedValue: 70,
                headerRosterScroll: false
            });
        }
    }

    getId = () => {
        let location = window.location.href;
        if (location.indexOf('?user=')) {
            let memberId = window.location.search.split('?user=');
            memberId = memberId[memberId.length - 1].split("&tid=");
            memberId = memberId[0];
            memberId = memberId || null;
            return memberId;
        }
        else {
            return null;
        }
    };

    init = () => {
        let dataFilter = this.dataFilter;
        if (this.state.flagTab === 1) {
            dataFilter.timeFrom = moment(this.state.arrDay[0]).startOf('day').toISOString();
            dataFilter.timeTo = moment(this.state.arrDay[this.state.arrDay.length - 1]).endOf('day').toISOString();
        }
        else if (this.state.flagTab === 0) {
            dataFilter.timeFrom = moment(this.state.onlyDay).startOf('day').toISOString();
            dataFilter.timeTo = moment(this.state.onlyDay).endOf('day').toISOString();
        }
        else {
            dataFilter.timeFrom = moment(this.state.arrDay[0]).startOf('day').toISOString();
            dataFilter.timeTo = moment(this.state.arrDay[this.state.arrDay.length - 1]).endOf('day').toISOString();
        }
        // dataFilter.timeFrom = this.state.arrDay[0];
        // dataFilter.timeTo = this.state.arrDay[this.state.arrDay.length - 1];
        let MemberId = this.getId();
        if (MemberId) dataFilter.staff = MemberId;
        this.props.rosterActions.getListRoster(dataFilter, (err, data) => {
            if (err) {
                // Config.popup.show('INFO',err.message);
                this.setState({
                    rosterList: [],
                    isloading: false
                });
            }
            else {
                this.setState({
                    rosterList: data,
                    isloading: false,
                });
            }
        });
        this.getShift();

        this.props.teamActions.getTeam({ id: this.state.idTeam }, (err, data) => {
            if (err) {
                this.setState({
                    isClockEnabled: false
                });
            }
            else {
                this.setState({
                    isClockEnabled: data.is_clock_enabled,
                    address: data.business_clock_in_address,
                    // radius: data.business_radius,
                    lat: data.business_clock_lat,
                    lng: data.business_clock_long
                });
            }
        })
    };

    getArrDay = (number) => {
        let week = this.state.week;
        let d = moment().startOf('week');
        let arr = [];
        d.add((week * 7), 'days');
        // let month = parseInt(moment().format('MM'),10);
        for (let i = 1; i <= 7; i++) {
            let day = d.add(1, 'days');
            arr.push(day.format());
        }
        if (arr.length <= 0 && number) {
            this.setState({
                week: parseInt(this.state.week, 10) - number
            });
            // return;
        }
        this.setState({
            arrDay: arr,
        }, () => {
            this.init();
        })
    };

    _getMemberTeamList = () => {
        this.props.teamActions.getMemberTeamList({ team: this.state.idTeam }, (err, data) => {
            if (err) {
                // Config.popup.show('INFO',err.message);
                this.setState({
                    memberList: []
                })
            }
            else {
                // that._goScreenTeamDetai();
                this.setState({
                    memberList: data
                })
            }
        });
    };

    changeTab = (idx) => {
        this.setState({
            flagTab: idx,
            rosterList: [],
            onlyDay: moment().format()
        }, () => {
            this.init();
        })
    };

    changeDropDownTeam = (data) => {
        let location = window.location.href;
        if (location.indexOf('?tid=')) {
            browserHistory.push('/roster');
        }
        if (this.refs['WeekView']) this.refs['WeekView'].clearDataChoose();
        this.setState({
            dataCreateStaff: [],
            idTeam: data.id
        }, () => {
            this._getMemberTeamList();
            this.init();
            this.getShift();
        });
    };

    changeWeek = (number) => {
        this.setState({
            week: parseInt(this.state.week, 10) + number,
            isloading: true,
        }, () => {
            this.getArrDay(number);
        });
    };

    resetWeek = () => {
        this.setState({
            week: 0
        }, () => {
            this.getArrDay();
        });
    };

    getShift = () => {

        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;

        let condition = { company: (checkLogin && checkLogin.company && checkLogin.company.id) ? checkLogin.company.id : null };
        this.dataFilter.condition = JSON.stringify(condition);
        //debugger;
        this.props.shiftActions.getListShift(this.dataFilter, (err, data) => {

            if (err) {
                // Config.popup.show('INFO',err.message);
                this.setState({
                    shiftList: []
                });
            }
            else {
                data = data.filter(item => !(item.name === "CUSTOM"));
                data.push(
                    {
                        color: "#EE766D",
                        company: null,
                        end: 0,
                        id: "OFF",
                        name: "OFF",
                        start: 0,
                        team: null
                    }
                );
                this.setState({
                    shiftList: data
                });
            }
        });
    };

    closeModal = () => {
        this.setState({ isRemoveMember: false });
      };
    closeCreateRosterModal = () => {
        this.setState({ isCreateNewRosterShift:false });
    };
    
    clockOutModal = () => {
        this.setState({ isClockedOut: true });
     }
    
    closeClockModal = () => {
       this.setState({ isClockedOut: false })
    }

    toggleCreateRoster = (arrMemberCreate) => {
        if (this.state.dataCreateStaff.length === 0 && this.state.flagTab === 1) {
            this.setState({ isCreateNewRosterShift: true });
            return;
        }
        if (this.state.shiftList.length === 0 && this.state.flagTab === 1) {
            //Config.popup.show('INFO', 'Please make at least one shift before create the roster');
            this.setState({ isRemoveMember: true });
            return;
        }
        this.setState({
            flagCreate: !this.state.flagCreate,
            arrMemberCreate: arrMemberCreate ? arrMemberCreate : null
        })
    };

    toggleInfo = (infoRoster) => {
        this.setState({
            dataInfo: infoRoster
        })
    };

    toggleConfirmRoster = () => {
        this.setState({
            flagConfirm: !this.state.flagConfirm
        })
    };

    toggleActivateClock = () => {
        this.setState({
            flagActivateClock: !this.state.flagActivateClock
        })
    };

    setDataListTeam = (data, id) => {
        this.setState({
            idTeam: this.state.idTeam ? this.state.idTeam : (id ? id : null)
        }, () => {
            this._getMemberTeamList();
            this.init();
            this.getShift();
        });
    };

    createShift = () => {
        browserHistory.push('/shift');
    };

    createStaffHours = () => {
        browserHistory.push('/staffhours');
    };

    getDataStaffChoose = (data) => {
        this.setState({
            dataCreateStaff: data
        })
    };

    toggleClock = (checked) => {
        this.setState({
            isClockEnabled: true,
            flagActivateClock: true
        });
        this.props.teamActions.toggleClock({ id: this.state.idTeam }, (err, data) => {
            if (err) {
                Config.alertMess(err);
            }
            else {
                console.log('toggleClock', data);                
                // this.setState({
                //     isClockEnabled: data[0].is_clock_enabled,
                //     flagActivateClock: true
                // });
            }
        });
    };

    toggleClockOut = () => {
        setTimeout(()=>{
            this.closeClockModal();
        },1000); 

        this.props.teamActions.toggleClock({ id: this.state.idTeam }, (err, data) => {
            if (err) {
                Config.alertMess(err);
            }
            else {
                this.setState({
                    isClockEnabled: data[0].is_clock_enabled,
                    flagActivateClock: true
                });
            }
        });
    };

    handleClockInOut = () => {
        // Handle clock in/out logic based on the current state
        if (this.state.isClockEnabled) {
            this.clockOutModal();
        } else {
            this.toggleClock();
        }
    };

    addRoster = (data, flagNoti, note, cb) => {
        let params = { team: this.state.idTeam, notification: flagNoti ? flagNoti : 'Y', rosters: JSON.stringify(data), note: note };
        this.props.rosterActions.addRoster(params, (err, data) => {

            if (err) {
                // Config.popup.show('INFO',err.message);
                cb && cb(err, data);
            }
            else {
                if (this.refs['WeekView']) this.refs['WeekView'].clearDataChoose();
                if (this.refs['DayView']) this.refs['DayView'].clearDataChoose();
                this.setState({
                    dataCreateStaff: [],
                    flagCreate: false,
                    flagConfirm: true,
                });
                this.init();
                cb && cb(null, data);
            }
        });
    };

    changeDay = (number) => {
        this.setState({
            arrMemberCreate: null,
            onlyDay: moment(this.state.onlyDay).add(number, 'day').format()
        }, () => {
            this.init();
        });
    };

    _removeRoster= (id, myTeam) => {
        
            let params = { id: id };
            if (myTeam && myTeam.id) {
                params.team = myTeam.id;
            }
            this.props.rosterActions.removeRoster(params, (err, data) => {
                if (err) {
                    Config.alertMess(err);
                }
                else {
                    this.setState({
                        dataInfo: null,
                    });
                    this.init();

                }
            });
        
    };

    _getRosterClock = (id, cb) => {
        this.props.rosterActions.getRosterClock(id, (err, data) => {
            if (err) {
                Config.alertMess(err);
                // this.setState({
                //     clockList: [],
                // });
                cb && cb(err, data);
            }
            else {
                this.setState({
                    clockList: data,
                });
                cb && cb(null, data);
            }
        });
    };

    editRoster = (data, cb) => {
        this.props.rosterActions.editRoster(data, (err, data) => {
            if (err) {
                Config.alertMess(err);
                cb && cb(err, data);
            }
            else {
                this.setState({
                    dataInfo: null,
                    flagConfirm: true
                });
                this.init();
                cb && cb(null, data);
            }
        });
    };

    popupInfo = (text) => {
        Config.popup.show('INFO', [text]);
    };

    changeTimeToSecond = (time) => {
        let a = time.toString().split('.');
        let second = Math.round(parseFloat('0.' + (a[1] || 0)) * 60);
        second = second <= 9 ? '0' + parseInt(second, 10) : parseInt(second, 10);
        a = a[0] + ':' + second;
        return a;

    };

    convertTimeToMinutes(timeString) {
        let [hours, minutes] = timeString.split(':');
        return parseInt(hours) * 60 + parseInt(minutes);
    }

    genCSVData = (arrDay, resultFilter, memberList, user) => {
        let res = [];
        memberList && memberList.map((key, idx) => {
            if (key.status === 'INVITING' || (user && user !== key.user.id)) return null;


            let arr = [];
            if (resultFilter) {
                arr = resultFilter.filter(i => i.staff && i.staff.id === key.user.id);
                if (arr.length > 0) res.push(arr[0]);
            }
        });

        const headerArray = [];
        const headerDetails = [];
        arrDay.map((val, idx) => {
            headerDetails[idx] = {};
            headerDetails[idx].label = moment(val).format('ddd').toUpperCase() + '\r\n' + moment(val).format('M/D/YY');
            if (idx == 0) {
                headerDetails[idx].key = 'mon';
            } if (idx == 1) {
                headerDetails[idx].key = 'tue';
            } if (idx == 2) {
                headerDetails[idx].key = 'wen';
            } if (idx == 3) {
                headerDetails[idx].key = 'the';
            } if (idx == 4) {
                headerDetails[idx].key = 'fri';
            } if (idx == 5) {
                headerDetails[idx].key = 'sat';
            } if (idx == 6) {
                headerDetails[idx].key = 'sun';
            }
            headerArray.push(headerDetails[idx]);
        });

        headerArray.push({ label: "SCHED" + '\r\n' + "HOURS", key: "total" });
        headerArray.push({ label: "ACTUAL" + '\r\n' + "HOURS", key: "clockedhrs" });
        headerArray.unshift({ label: "EMPLOYEE NAME", key: "fullName" });
        const headers = headerArray;
        const data = [];
        const dataDetails = [];


        if (res.length > 0) {
            let i = 0;
            for (const row of res) {
                const { activities, staff } = row;
                dataDetails[i] = {};

                for (let [index, acti] of activities.entries()) {
                    let Day = acti.time ? moment(acti.time).format('ddd') : '';

                    if (Day == 'Mon') {
                        let monHour;
                        let clockInMinutes;
                        let clockOutMinutes;
                        let monClock;
                        if(acti.clock_in && acti.clock_out){
                            clockInMinutes = this.convertTimeToMinutes(acti.clock_in);
                            clockOutMinutes = this.convertTimeToMinutes(acti.clock_out);
                        }

                        if (acti.shift === null) {
                            monHour = 0;
                        }
                        else if (acti.shift.end < acti.shift.start) {
                            monHour = (acti.shift.end + 24 - acti.shift.start);
                        } else {
                            monHour = (acti.shift.end - acti.shift.start);
                        }

                        if((acti.clock_in === "") || (acti.clock_out === "")){
                            monClock = 0;
                        }
                        else{
                            let timeDifferenceMinutes = clockOutMinutes - clockInMinutes;
                            let hours = Math.floor(timeDifferenceMinutes / 60);
                            let minutes = timeDifferenceMinutes % 60;
                            monClock = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                            console.log(monClock);
                        }

                        dataDetails[i].mon = this.changeTimeToSecond(monHour);
                        if (monClock) {
                        dataDetails[i].mon = `${dataDetails[i].mon} (${monClock})`
                        }
                        else{
                            dataDetails[i].mon = dataDetails[i].mon;
                        }
                        dataDetails[i].total = (dataDetails[i].total);
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs);
                        let MondayHours = dataDetails[i].mon;
                        let MondayHoursValue = MondayHours.split(':')
                        let MondayMinute = parseInt(MondayHoursValue[1]).toString().padStart(2, '0');
                        let MondayHour = parseInt(MondayHoursValue[0]).toString().padStart(2, '0');
                        
                        function divideBy60(MondayMinute) {
                            let quotient = Math.floor(MondayMinute / 60);
                            let remainder = MondayMinute % 60;
                          
                            return { quotient, remainder };
                        }

                        let MondayClockedHours = monClock;
                        if(MondayClockedHours != 0){
                        let MondayClockHoursValue = MondayClockedHours.split(':')                       
                        let MondayClockMinute = parseInt(MondayClockHoursValue[1]).toString().padStart(2, '0');
                        let MondayClockHour = parseInt(MondayClockHoursValue[0]).toString().padStart(2, '0');

                        function divide(MondayClockMinute){
                            let quotients = Math.floor(MondayClockMinute / 60);
                            let remain = MondayClockMinute % 60;
                            return { quotients, remain };
                        }
                    
                        function getClockTotalTime(clockedhrs, hour, min) {
                            let hourWithMin = clockedhrs.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotients, remain } = divide(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotients)).toString().padStart(2, '0');
                            let totalMinutes = remain.toString().padStart(2, '0');
                            let returnHour = parseInt(hour) + parseInt(totalHours);
                            let returntotalMin = returnHour + ":" + totalMinutes;
                            return returnHour < 24 ? returntotalMin + ":00" : returntotalMin;
                        }
                    
                        let { quotients, remain } = divide(MondayClockMinute);

                        let TotalMondayClockHrs = (parseInt(MondayClockHour) + parseInt(quotients)).toString().padStart(2, '0');
                        let TotalMondayClockMinutes = remain.toString().padStart(2, '0');
                        let MondayClockTotalTime = TotalMondayClockHrs + ":" + TotalMondayClockMinutes;
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs) ? getClockTotalTime(dataDetails[i].clockedhrs, TotalMondayClockHrs, TotalMondayClockMinutes) : MondayClockTotalTime;

                        }

                        function getTotalTime(total,hour,min){
                            let hourWithMin = total.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotient, remainder } = divideBy60(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotient)).toString().padStart(2, '0');
                            let totalMinutes = remainder.toString().padStart(2, '0');
                            let returnHour= parseInt(hour)+parseInt(totalHours);
                            let returntotalMin=returnHour+ ":" +totalMinutes;
                            return returnHour<24?returntotalMin+":00":returntotalMin;
                            
                        }
                        let { quotient, remainder } = divideBy60(MondayMinute);
                      
                        let TotalMondayHours = (parseInt(MondayHour) + parseInt(quotient)).toString().padStart(2, '0');
                        let TotalMondayMinutes = remainder.toString().padStart(2, '0');
                        let MondayTotalTime = TotalMondayHours + ":" + TotalMondayMinutes;
                        dataDetails[i].total = (dataDetails[i].total) ? getTotalTime(dataDetails[i].total,TotalMondayHours,TotalMondayMinutes) : MondayTotalTime;

                    }

                    if (Day == 'Tue') {
                        let tueHour;
                        let clockInMinutes;
                        let clockOutMinutes;
                        let tueClock;
                        if(acti.clock_in && acti.clock_out){
                            clockInMinutes = this.convertTimeToMinutes(acti.clock_in);
                            clockOutMinutes = this.convertTimeToMinutes(acti.clock_out);
                        }
                        if (acti.shift === null) {
                            tueHour = 0;
                        }
                        else if (acti.shift.end < acti.shift.start) {
                            tueHour = (acti.shift.end + 24 - acti.shift.start);
                        } else {
                            tueHour = (acti.shift.end - acti.shift.start);
                        }

                        if((acti.clock_in === "") || (acti.clock_out === "")){
                            tueClock = 0;
                        }
                        else{
                            let timeDifferenceMinutes = clockOutMinutes - clockInMinutes;
                            let hours = Math.floor(timeDifferenceMinutes / 60);
                            let minutes = timeDifferenceMinutes % 60;
                            tueClock = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                            console.log(tueClock);
                        }
                        dataDetails[i].tue = this.changeTimeToSecond(tueHour);
                        if (tueClock) {
                        dataDetails[i].tue = `${dataDetails[i].tue} (${tueClock})`
                        }
                        else{
                            dataDetails[i].tue = dataDetails[i].tue;
                        }                        
                        // dataDetails[i].total = (dataDetails[i].total) ? dataDetails[i].total + tueHour : tueHour;
                        dataDetails[i].total = (dataDetails[i].total);
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs);
                        let TuesdayHours = dataDetails[i].tue;
                        let TuesdayHoursValue = TuesdayHours.split(':')
                        let TuesdayHour = parseInt(TuesdayHoursValue[0]).toString().padStart(2, '0')
                        let TuesdayMinute = parseInt(TuesdayHoursValue[1]).toString().padStart(2, '0')
                       
                        function divideBy60(TuesdayMinute) {
                            let quotient = Math.floor(TuesdayMinute / 60);
                            let remainder = TuesdayMinute % 60;
                            return { quotient, remainder };
                          
                        }

                        let TuesdayClockedHours = tueClock;
                        if(TuesdayClockedHours != 0){
                        let TuesdayClockHoursValue = TuesdayClockedHours.split(':')                       
                        let TuesdayClockMinute = parseInt(TuesdayClockHoursValue[1]).toString().padStart(2, '0');
                        let TuesdayClockHour = parseInt(TuesdayClockHoursValue[0]).toString().padStart(2, '0');

                        function divide(TuesdayClockMinute){
                            let quotients = Math.floor(TuesdayClockMinute / 60);
                            let remain = TuesdayClockMinute % 60;
                            return { quotients, remain };
                        }
                        let { quotients, remain } = divide(TuesdayClockMinute);
                    
                        function getClockTotalTime(clockedhrs, hour, min) {
                            let hourWithMin = clockedhrs.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotients, remain } = divide(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotients)).toString().padStart(2, '0');
                            let totalMinutes = remain.toString().padStart(2, '0');
                            let returnHour = parseInt(hour) + parseInt(totalHours);
                            let returntotalMin = returnHour + ":" + totalMinutes;
                            return returnHour < 24 ? returntotalMin + ":00" : returntotalMin;
                        }

                        let TotalTuesdayClockHrs = (parseInt(TuesdayClockHour) + parseInt(quotients)).toString().padStart(2, '0');
                        let TotalTuesdayClockMinutes = remain.toString().padStart(2, '0');
                        let TuesdayClockTotalTime = TotalTuesdayClockHrs + ":" + TotalTuesdayClockMinutes;
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs) ? getClockTotalTime(dataDetails[i].clockedhrs, TotalTuesdayClockHrs, TotalTuesdayClockMinutes) : TuesdayClockTotalTime;

                        }

                        function getTotalTime(total,hour,min){
                            let hourWithMin = total.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotient, remainder } = divideBy60(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotient)).toString().padStart(2, '0');
                            let totalMinutes = remainder.toString().padStart(2, '0');
                            let returnHour= parseInt(hour)+parseInt(totalHours);
                            let returntotalMin=returnHour+ ":" +totalMinutes;
                            return returnHour<24?returntotalMin+":00":returntotalMin;
                            
                        }

                        let { quotient, remainder } = divideBy60(TuesdayMinute);
                      
                        let TotalTuesdayHours = (parseInt(TuesdayHour) + parseInt(quotient)).toString().padStart(2, '0');
                        let TotalTuesdayMinute = remainder.toString().padStart(2, '0');
                        let TuesdayTotalTime = TotalTuesdayHours + ":" + TotalTuesdayMinute;
                        dataDetails[i].total = (dataDetails[i].total) ? getTotalTime(dataDetails[i].total,TotalTuesdayHours,TotalTuesdayMinute) : TuesdayTotalTime;
                    }

                    if (Day == 'Wed') {
                        let wenHour;
                        let clockInMinutes;
                        let clockOutMinutes;
                        let WedClock;
                        if(acti.clock_in && acti.clock_out){
                            clockInMinutes = this.convertTimeToMinutes(acti.clock_in);
                            clockOutMinutes = this.convertTimeToMinutes(acti.clock_out);
                        }

                        if (acti.shift === null) {
                            wenHour = 0;
                        }
                        else if (acti.shift.end < acti.shift.start) {
                            wenHour = (acti.shift.end + 24 - acti.shift.start);
                        } else {
                            wenHour = (acti.shift.end - acti.shift.start);
                        }
                        dataDetails[i].wen = this.changeTimeToSecond(wenHour);

                        if((acti.clock_in === "") || (acti.clock_out === "")){
                            WedClock = 0;
                        }
                        else{
                            let timeDifferenceMinutes = clockOutMinutes - clockInMinutes;
                            let hours = Math.floor(timeDifferenceMinutes / 60);
                            let minutes = timeDifferenceMinutes % 60;
                            WedClock = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                            console.log(WedClock);
                        }
                        if (WedClock) {
                            dataDetails[i].wen = `${dataDetails[i].wen} (${WedClock})`
                            }
                            else{
                                dataDetails[i].wen = dataDetails[i].wen;
                        } 
                        // dataDetails[i].total = (dataDetails[i].total) ? dataDetails[i].total + wenHour : wenHour;
                        dataDetails[i].total = (dataDetails[i].total);
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs);
                        let WednesdayHours = dataDetails[i].wen;
                        let WednesdayHoursValue = WednesdayHours.split(':')
                        let WednesdayHour = parseInt(WednesdayHoursValue[0]).toString().padStart(2, '0')
                        let WednesdayMinute = parseInt(WednesdayHoursValue[1]).toString().padStart(2, '0')
                       

                        function divideBy60(WednesdayMinute) {
                            let quotient = Math.floor(WednesdayMinute / 60);
                            let remainder = WednesdayMinute % 60;
                            return { quotient, remainder };
                          
                        }

                        let WednesdayClockedHours = WedClock;
                        if(WednesdayClockedHours != 0){
                        let WednesdayClockHoursValue = WednesdayClockedHours.split(':')                       
                        let WednesdayClockMinute = parseInt(WednesdayClockHoursValue[1]).toString().padStart(2, '0');
                        let WednesdayClockHour = parseInt(WednesdayClockHoursValue[0]).toString().padStart(2, '0');

                        function divide(WednesdayClockMinute){
                            let quotients = Math.floor(WednesdayClockMinute / 60);
                            let remain = WednesdayClockMinute % 60;
                            return { quotients, remain };
                        }
                    
                        function getClockTotalTime(clockedhrs, hour, min) {
                            let hourWithMin = clockedhrs.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotients, remain } = divide(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotients)).toString().padStart(2, '0');
                            let totalMinutes = remain.toString().padStart(2, '0');
                            let returnHour = parseInt(hour) + parseInt(totalHours);
                            let returntotalMin = returnHour + ":" + totalMinutes;
                            return returnHour < 24 ? returntotalMin + ":00" : returntotalMin;
                        }
                    
                        let { quotients, remain } = divide(WednesdayClockMinute);

                        let TotalWednesdayClockHrs = (parseInt(WednesdayClockHour) + parseInt(quotients)).toString().padStart(2, '0');
                        let TotalWednesdayClockMinutes = remain.toString().padStart(2, '0');
                        let WednesdayClockTotalTime = TotalWednesdayClockHrs + ":" + TotalWednesdayClockMinutes;
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs) ? getClockTotalTime(dataDetails[i].clockedhrs, TotalWednesdayClockHrs, TotalWednesdayClockMinutes) : WednesdayClockTotalTime;

                        }

                        function getTotalTime(total,hour,min){
                            let hourWithMin = total.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotient, remainder } = divideBy60(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotient)).toString().padStart(2, '0');
                            let totalMinutes = remainder.toString().padStart(2, '0');
                            let returnHour= parseInt(hour)+parseInt(totalHours);
                            let returntotalMin=returnHour+ ":" +totalMinutes;
                            return returnHour<24?returntotalMin+":00":returntotalMin;
                            
                        }

                        let { quotient, remainder } = divideBy60(WednesdayMinute);
                      
                        let TotalWednesdayHours = (parseInt(WednesdayHour) + parseInt(quotient)).toString().padStart(2, '0');
                        let TotalWednesdayMinute = remainder.toString().padStart(2, '0');
                        let WednesdayTotalTime = TotalWednesdayHours + ":" + TotalWednesdayMinute;
                        dataDetails[i].total = (dataDetails[i].total) ? getTotalTime(dataDetails[i].total,TotalWednesdayHours,TotalWednesdayMinute) : WednesdayTotalTime;
                    }

                    if (Day == 'Thu') {
                        let theHour;
                        let clockInMinutes;
                        let clockOutMinutes;
                        let ThuClock;
                        if(acti.clock_in && acti.clock_out){
                            clockInMinutes = this.convertTimeToMinutes(acti.clock_in);
                            clockOutMinutes = this.convertTimeToMinutes(acti.clock_out);
                        }
                        if (acti.shift === null) {
                            theHour = 0;
                        }
                        else if (acti.shift.end < acti.shift.start) {
                            theHour = (acti.shift.end + 24 - acti.shift.start);
                        } else {
                            theHour = (acti.shift.end - acti.shift.start);
                        }
                        dataDetails[i].the = this.changeTimeToSecond(theHour);
                        
                        if((acti.clock_in === "") || (acti.clock_out === "")){
                            ThuClock = 0;
                        }
                        else{
                            let timeDifferenceMinutes = clockOutMinutes - clockInMinutes;
                            let hours = Math.floor(timeDifferenceMinutes / 60);
                            let minutes = timeDifferenceMinutes % 60;
                            ThuClock = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                            console.log(ThuClock);
                        }
                        if (ThuClock) {
                            dataDetails[i].the = `${dataDetails[i].the} (${ThuClock})`
                            }
                            else{
                                dataDetails[i].the = dataDetails[i].the;
                        }
                        //dataDetails[i].total = (dataDetails[i].total) ? dataDetails[i].total + theHour : theHour;
                        dataDetails[i].total = (dataDetails[i].total);
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs);
                        let ThursdayHours = dataDetails[i].the;
                        let ThursdayHoursValue = ThursdayHours.split(':')
                        let ThursdayHour = parseInt(ThursdayHoursValue[0]).toString().padStart(2, '0')
                        let ThursdayMinute = parseInt(ThursdayHoursValue[1]).toString().padStart(2, '0')

                        function divideBy60(ThusdayMinute) {
                            let quotient = Math.floor(ThusdayMinute / 60);
                            let remainder = ThusdayMinute % 60;
                            return { quotient, remainder };
                        }

                        let ThusdayClockedHours = ThuClock;
                        if(ThusdayClockedHours != 0){
                        let ThusdayClockHoursValue = ThusdayClockedHours.split(':')                       
                        let ThusdayClockMinute = parseInt(ThusdayClockHoursValue[1]).toString().padStart(2, '0');
                        let ThusdayClockHour = parseInt(ThusdayClockHoursValue[0]).toString().padStart(2, '0');

                        function divide(ThusdayClockMinute){
                            let quotients = Math.floor(ThusdayClockMinute / 60);
                            let remain = ThusdayClockMinute % 60;
                            return { quotients, remain };
                        }
                    
                        function getClockTotalTime(clockedhrs, hour, min) {
                            let hourWithMin = clockedhrs.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotients, remain } = divide(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotients)).toString().padStart(2, '0');
                            let totalMinutes = remain.toString().padStart(2, '0');
                            let returnHour = parseInt(hour) + parseInt(totalHours);
                            let returntotalMin = returnHour + ":" + totalMinutes;
                            return returnHour < 24 ? returntotalMin + ":00" : returntotalMin;
                        }
                    
                        let { quotients, remain } = divide(ThusdayClockMinute);

                        let TotalThusdayClockHrs = (parseInt(ThusdayClockHour) + parseInt(quotients)).toString().padStart(2, '0');
                        let TotalThusdayClockMinutes = remain.toString().padStart(2, '0');
                        let ThusdayClockTotalTime = TotalThusdayClockHrs + ":" + TotalThusdayClockMinutes;
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs) ? getClockTotalTime(dataDetails[i].clockedhrs, TotalThusdayClockHrs, TotalThusdayClockMinutes) : ThusdayClockTotalTime;

                        }

                        function getTotalTime(total,hour,min){
                            let hourWithMin = total.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotient, remainder } = divideBy60(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotient)).toString().padStart(2, '0');
                            let totalMinutes = remainder.toString().padStart(2, '0');
                            let returnHour= parseInt(hour)+parseInt(totalHours);
                            let returntotalMin=returnHour+ ":" +totalMinutes;
                            return returnHour<24?returntotalMin+":00":returntotalMin;
                            
                        }
                        let { quotient, remainder } = divideBy60(ThursdayMinute);
                        let TotalThursdayHours =(parseInt(ThursdayHour) + parseInt(quotient)).toString().padStart(2, '0');
                        let TotalThursdayMinute = remainder.toString().padStart(2, '0');
                        let ThursdayTotalTime = TotalThursdayHours + ":" + TotalThursdayMinute;
                        dataDetails[i].total = (dataDetails[i].total) ? getTotalTime(dataDetails[i].total,TotalThursdayHours,TotalThursdayMinute) : ThursdayTotalTime;
                    }
                    if (Day == 'Fri') {
                        let friHour;
                        let clockInMinutes;
                        let clockOutMinutes;
                        let friClock;
                        if(acti.clock_in && acti.clock_out){
                            clockInMinutes = this.convertTimeToMinutes(acti.clock_in);
                            clockOutMinutes = this.convertTimeToMinutes(acti.clock_out);
                        }

                        if (acti.shift === null) {
                            friHour = 0;
                        }
                        else if (acti.shift.end < acti.shift.start) {
                            friHour = (acti.shift.end + 24 - acti.shift.start);
                        } else {
                            friHour = (acti.shift.end - acti.shift.start);
                        }
                        dataDetails[i].fri = this.changeTimeToSecond(friHour);
                        if((acti.clock_in === "") || (acti.clock_out === "")){
                            friClock = 0;
                        }
                        else{
                            let timeDifferenceMinutes = clockOutMinutes - clockInMinutes;
                            let hours = Math.floor(timeDifferenceMinutes / 60);
                            let minutes = timeDifferenceMinutes % 60;
                            friClock = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                            console.log(friClock);
                        }
                        if (friClock) {
                            dataDetails[i].fri = `${dataDetails[i].fri} (${friClock})`
                            }
                            else{
                                dataDetails[i].fri = dataDetails[i].fri;
                        }
                        // dataDetails[i].total = (dataDetails[i].total) ? dataDetails[i].total + friHour : friHour;
                        dataDetails[i].total = (dataDetails[i].total);
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs);
                        let FridayHours = dataDetails[i].fri;
                        let FridayHoursValue = FridayHours.split(':')
                        let FridayHour = parseInt(FridayHoursValue[0]).toString().padStart(2, '0')
                        let FridayMinute = parseInt(FridayHoursValue[1]).toString().padStart(2, '0')


                        function divideBy60(FridayMinute) {
                            let quotient = Math.floor(FridayMinute / 60);
                            let remainder = FridayMinute % 60;
                            return { quotient, remainder };
                        }

                        let FridayClockedHours = friClock;
                        if(FridayClockedHours != 0){
                        let FridayClockHoursValue = FridayClockedHours.split(':')                       
                        let FridayClockMinute = parseInt(FridayClockHoursValue[1]).toString().padStart(2, '0');
                        let FridayClockHour = parseInt(FridayClockHoursValue[0]).toString().padStart(2, '0');

                        function divide(FridayClockMinute){
                            let quotients = Math.floor(FridayClockMinute / 60);
                            let remain = FridayClockMinute % 60;
                            return { quotients, remain };
                        }
                    
                        function getClockTotalTime(clockedhrs, hour, min) {
                            let hourWithMin = clockedhrs.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotients, remain } = divide(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotients)).toString().padStart(2, '0');
                            let totalMinutes = remain.toString().padStart(2, '0');
                            let returnHour = parseInt(hour) + parseInt(totalHours);
                            let returntotalMin = returnHour + ":" + totalMinutes;
                            return returnHour < 24 ? returntotalMin + ":00" : returntotalMin;
                        }
                    
                        let { quotients, remain } = divide(FridayClockMinute);

                        let TotalFridayClockHrs = (parseInt(FridayClockHour) + parseInt(quotients)).toString().padStart(2, '0');
                        let TotalFridayClockMinutes = remain.toString().padStart(2, '0');
                        let FridayClockTotalTime = TotalFridayClockHrs + ":" + TotalFridayClockMinutes;
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs) ? getClockTotalTime(dataDetails[i].clockedhrs, TotalFridayClockHrs, TotalFridayClockMinutes) : FridayClockTotalTime;

                        }

                        function getTotalTime(total,hour,min){
                            let hourWithMin = total.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotient, remainder } = divideBy60(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotient)).toString().padStart(2, '0');
                            let totalMinutes = remainder.toString().padStart(2, '0');
                            let returnHour= parseInt(hour)+parseInt(totalHours);
                            let returntotalMin=returnHour+ ":" +totalMinutes;
                            return returnHour<24?returntotalMin+":00":returntotalMin;
                            
                        }
                        let { quotient, remainder } = divideBy60(FridayMinute);
                        let TotalFridayHours = (parseInt(FridayHour) + parseInt(quotient)).toString().padStart(2, '0');
                        let TotalFridayMinute = remainder.toString().padStart(2, '0');
                        let FridayTotalTime = TotalFridayHours + ":" + TotalFridayMinute;
                        dataDetails[i].total = (dataDetails[i].total) ? getTotalTime(dataDetails[i].total,TotalFridayHours,TotalFridayMinute) : FridayTotalTime;
                    }
                    if (Day == 'Sat') {
                        let satHour;
                        let clockInMinutes;
                        let clockOutMinutes;
                        let satClock;
                        if(acti.clock_in && acti.clock_out){
                            clockInMinutes = this.convertTimeToMinutes(acti.clock_in);
                            clockOutMinutes = this.convertTimeToMinutes(acti.clock_out);
                        }
                        if (acti.shift === null) {
                            satHour = 0;
                        }
                        else if (acti.shift.end < acti.shift.start) {
                            satHour = (acti.shift.end + 24 - acti.shift.start);
                        } else {
                            satHour = (acti.shift.end - acti.shift.start);

                        }
                        dataDetails[i].sat = this.changeTimeToSecond(satHour);
                        if((acti.clock_in === "") || (acti.clock_out === "")){
                            satClock = 0;
                        }
                        else{
                            let timeDifferenceMinutes = clockOutMinutes - clockInMinutes;
                            let hours = Math.floor(timeDifferenceMinutes / 60);
                            let minutes = timeDifferenceMinutes % 60;
                            satClock = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                            console.log(satClock);
                        }
                        if (satClock) {
                            dataDetails[i].sat = `${dataDetails[i].sat} (${satClock})`
                            }
                            else{
                                dataDetails[i].sat = dataDetails[i].sat;
                        }
                        //dataDetails[i].total = (dataDetails[i].total) ? dataDetails[i].total + satHour : satHour;
                        dataDetails[i].total = (dataDetails[i].total);
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs);
                        let SaturdayHours = dataDetails[i].sat;
                        let SaturdayHoursValue = SaturdayHours.split(':')
                        let SaturdayHour = parseInt(SaturdayHoursValue[0]).toString().padStart(2, '0')
                        let SaturdayMinute = parseInt(SaturdayHoursValue[1]).toString().padStart(2, '0')
                        

                        function divideBy60(SaturdayMinute) {
                            let quotient = Math.floor(SaturdayMinute / 60);
                            let remainder = SaturdayMinute % 60;
                            return { quotient, remainder };
                      
                        }
                        let SaturdayClockedHours = satClock;
                        if(SaturdayClockedHours != 0){
                        let SaturdayClockHoursValue = SaturdayClockedHours.split(':')                       
                        let SaturdayClockMinute = parseInt(SaturdayClockHoursValue[1]).toString().padStart(2, '0');
                        let SaturdayClockHour = parseInt(SaturdayClockHoursValue[0]).toString().padStart(2, '0');

                        function divide(SaturdayClockMinute){
                            let quotients = Math.floor(SaturdayClockMinute / 60);
                            let remain = SaturdayClockMinute % 60;
                            return { quotients, remain };
                        }
                    
                        function getClockTotalTime(clockedhrs, hour, min) {
                            let hourWithMin = clockedhrs.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotients, remain } = divide(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotients)).toString().padStart(2, '0');
                            let totalMinutes = remain.toString().padStart(2, '0');
                            let returnHour = parseInt(hour) + parseInt(totalHours);
                            let returntotalMin = returnHour + ":" + totalMinutes;
                            return returnHour < 24 ? returntotalMin + ":00" : returntotalMin;
                        }
                    
                        let { quotients, remain } = divide(SaturdayClockMinute);

                        let TotalSaturdayClockHrs = (parseInt(SaturdayClockHour) + parseInt(quotients)).toString().padStart(2, '0');
                        let TotalSaturdayClockMinutes = remain.toString().padStart(2, '0');
                        let SaturdayClockTotalTime = TotalSaturdayClockHrs + ":" + TotalSaturdayClockMinutes;
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs) ? getClockTotalTime(dataDetails[i].clockedhrs, TotalSaturdayClockHrs, TotalSaturdayClockMinutes) : SaturdayClockTotalTime;

                        }
                        function getTotalTime(total,hour,min){
                            let hourWithMin = total.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotient, remainder } = divideBy60(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotient)).toString().padStart(2, '0');
                            let totalMinutes = remainder.toString().padStart(2, '0');
                            let returnHour= parseInt(hour)+parseInt(totalHours);
                            let returntotalMin=returnHour+ ":" +totalMinutes;
                            return returnHour<24?returntotalMin+":00":returntotalMin;
                            
                        }
                        let { quotient, remainder } = divideBy60(SaturdayMinute);
                        let TotalSaturdayHours = (parseInt(SaturdayHour) + parseInt(quotient)).toString().padStart(2, '0');
                        let TotalSaturdayMinute = remainder.toString().padStart(2, '0');
                        let SaturdayTotalTime = TotalSaturdayHours + ":" + TotalSaturdayMinute;
                        dataDetails[i].total = (dataDetails[i].total) ? getTotalTime(dataDetails[i].total,TotalSaturdayHours,TotalSaturdayMinute) : SaturdayTotalTime;
                    }
                    if (Day == 'Sun') {
                        let sunHour;
                        let clockInMinutes;
                        let clockOutMinutes;
                        let sunClock;
                        if(acti.clock_in && acti.clock_out){
                            clockInMinutes = this.convertTimeToMinutes(acti.clock_in);
                            clockOutMinutes = this.convertTimeToMinutes(acti.clock_out);
                        }
                        if (acti.shift === null) {
                            sunHour = 0;
                        }
                        else if (acti.shift.end < acti.shift.start) {
                            sunHour = (acti.shift.end + 24 - acti.shift.start);
                        } else {
                            sunHour = (acti.shift.end - acti.shift.start);
                        }
                        dataDetails[i].sun = this.changeTimeToSecond(sunHour);
                        if((acti.clock_in === "") || (acti.clock_out === "")){
                            sunClock = 0;
                        }
                        else{
                            let timeDifferenceMinutes = clockOutMinutes - clockInMinutes;
                            let hours = Math.floor(timeDifferenceMinutes / 60);
                            let minutes = timeDifferenceMinutes % 60;
                            sunClock = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                            console.log(sunClock);
                        }
                        if (sunClock) {
                            dataDetails[i].sun = `${dataDetails[i].sun} (${sunClock})`
                            }
                            else{
                                dataDetails[i].sun = dataDetails[i].sun;
                        }
                        // dataDetails[i].total = (dataDetails[i].total) ? dataDetails[i].total + sunHour : sunHour;
                        dataDetails[i].total = (dataDetails[i].total);
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs);
                        let SundayHours = dataDetails[i].sun;
                        let SundayHoursValue = SundayHours.split(':')
                        let SundayHour = parseInt(SundayHoursValue[0]).toString().padStart(2, '0')
                        let SundayMinute = parseInt(SundayHoursValue[1]).toString().padStart(2, '0')


                        function divideBy60(SundayMinute) {
                            let quotient = Math.floor(SundayMinute / 60);
                            let remainder = SundayMinute % 60;
                            return { quotient, remainder };
                        }

                        let SundayClockedHours = sunClock;
                        if(SundayClockedHours != 0){
                        let SundayClockHoursValue = SundayClockedHours.split(':')                       
                        let SundayClockMinute = parseInt(SundayClockHoursValue[1]).toString().padStart(2, '0');
                        let SundayClockHour = parseInt(SundayClockHoursValue[0]).toString().padStart(2, '0');

                        function divide(SundayClockMinute){
                            let quotients = Math.floor(SundayClockMinute / 60);
                            let remain = SundayClockMinute % 60;
                            return { quotients, remain };
                        }
                    
                        function getClockTotalTime(clockedhrs, hour, min) {
                            let hourWithMin = clockedhrs.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotients, remain } = divide(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotients)).toString().padStart(2, '0');
                            let totalMinutes = remain.toString().padStart(2, '0');
                            let returnHour = parseInt(hour) + parseInt(totalHours);
                            let returntotalMin = returnHour + ":" + totalMinutes;
                            return returnHour < 24 ? returntotalMin + ":00" : returntotalMin;
                        }
                    
                        let { quotients, remain } = divide(SundayClockMinute);

                        let TotalSundayClockHrs = (parseInt(SundayClockHour) + parseInt(quotients)).toString().padStart(2, '0');
                        let TotalSundayClockMinutes = remain.toString().padStart(2, '0');
                        let SundayClockTotalTime = TotalSundayClockHrs + ":" + TotalSundayClockMinutes;
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs) ? getClockTotalTime(dataDetails[i].clockedhrs, TotalSundayClockHrs, TotalSundayClockMinutes) : SundayClockTotalTime;

                        }
                        function getTotalTime(total,hour,min){
                            let hourWithMin = total.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotient, remainder } = divideBy60(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotient)).toString().padStart(2, '0');
                            let totalMinutes = remainder.toString().padStart(2, '0');
                            let returnHour= parseInt(hour)+parseInt(totalHours);
                            let returntotalMin=returnHour+ ":" +totalMinutes;
                            return returnHour<24?returntotalMin+":00":returntotalMin;
                            
                        }
                        let { quotient, remainder } = divideBy60(SundayMinute);
                        let TotalSundayHours = (parseInt(SundayHour) + parseInt(quotient)).toString().padStart(2, '0');
                        let TotalSundayMinute = remainder.toString().padStart(2, '0');
                        let SundayTotalTime = TotalSundayHours + ":" + TotalSundayMinute;
                        dataDetails[i].total = (dataDetails[i].total) ? getTotalTime(dataDetails[i].total,TotalSundayHours,TotalSundayMinute) : SundayTotalTime;
                    }
                }
                if ((Object.keys(res).length) == i) {
                    break;
                } else {
                    dataDetails[i].fullName = staff.fullName;
                    data.push(dataDetails[i]);
                    i++;
                }

            }
        }
        return {
            headers,
            data
        };

        onLogout = () => {
            this.refs.OverlayTriggerExport1.hide();
            this.refs.OverlayTriggerExport2.hide();
        };
    }
    render() {
        let { teamList, memberList } = this.props;
        let dataTab = ['DAY', 'WEEK'];
        let myTeam = [];
        if (teamList && this.state.idTeam) {
            myTeam = teamList.find(i => { return (i.id === this.state.idTeam) });
        }

        let resultFilter = [];
        if (this.state.rosterList && memberList) {
            let arrTeam = this.state.rosterList.slice(0);
            arrTeam.forEach(function (hash) {
                return function (a) {
                    if (a.staff && a.staff.id && !hash[a.staff.id]) {
                        hash[a.staff.id] = { staff: a.staff, activities: [] };
                        resultFilter.push(hash[a.staff.id]);
                    }
                    if (a.staff && a.staff.id) hash[a.staff.id].activities.push(a);
                };
            }(Object.create(null)));
            for (let i in memberList) {
                let arr = resultFilter.filter(j => j.staff && j.staff.id && j.staff.id === memberList[i].user.id);
                if (arr.length === 0) {
                    resultFilter.push({ staff: memberList[i].user, activities: [] });
                }
            }
        }

        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;

        let showCreareShift = null;
        if (checkLogin.company && checkLogin.company.id && memberList && memberList.length > 0) {
            showCreareShift = memberList.filter(i => i.role === "OWNER" || i.role === "AdMIN" && checkLogin.user.id === i.user.id);
            memberList.filter((i => console.log("=======>", i)))
            showCreareShift = showCreareShift.length > 0;
        }

        let isAdmin = null;
        let isOwner = null;
        if (memberList) {
            isAdmin = memberList.filter(i => ((i.role === 'ADMIN') && i.user && checkLogin.user && i.user.id === checkLogin.user.id));
            isOwner = memberList.filter(i => ((i.role === 'OWNER') && i.user && checkLogin.user && i.user.id === checkLogin.user.id));
        }

        let MemberId = this.getId();

        const csvData = this.genCSVData(this.state.arrDay, resultFilter, memberList, MemberId);

        return (
            <div className="full_w full_h height-100vh" style={{ position: 'relative' }}>
                {!!this.state.isLoadingExport &&
                    <div className="loading-pdf">
                        <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30} />
                    </div>
                }
                <div className="register calendar-view row my-team-detail my-team roster-view">
                    <div className="col-sm-12 display_col padding-0 full_h_1" style={{ position: 'relative' }}>
                        <div className={`search-bar col-sm-12 shadow-1 display_row align-center sub-header-fixed ${this.state.headerRosterScroll ? 'header-roster-scroll' : ''}`} style={{ top: this.state.fixedValue }}>
                            <SearchBar isSearch={true}
                                teamList={teamList}
                                myTeam={myTeam}
                                dataListTeam={this.setDataListTeam}
                                changeDropDownTeam={this.changeDropDownTeam} />
                            <div className="search-view" style={{ flexWrap: 'wrap' }}>
                                {/*TODO: permission to view clock in/out*/}
                                <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={<Tooltip id="edit">View Staff Hours</Tooltip>} >
                                    <div className="display_row align-center btn-new-event button-resp">
                                        {/* <div className="toggle-switch small-switch">
                                            <input type="checkbox" className="toggle-switch-checkbox"
                                                name="toggleSwitch" id="toggleSwitches"
                                                checked={this.state.isClockEnabled}
                                                onChange={e => this.toggleClock(e.target.checked)}
                                            />
                                            <label className="toggle-switch-label" htmlFor="toggleSwitches">
                                                <span className="toggle-switch-inner"></span>
                                                <span className="toggle-switch-switch"></span>
                                            </label>
                                        </div> */}
                                    </div> 
                                </OverlayTrigger>
                              <div className="display_row align-center btn-new-event button-not-resp">
                                    <div className="toggle-switch small-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            id="clocktoggleSwitch"
                                            checked={this.state.isClockEnabled}
                                            onChange={this.handleClockInOut}
                                        />
                                        <label className="toggle-switch-label" htmlFor="clocktoggleSwitch">
                                            <span className="toggle-switch-inner"></span>
                                            <span className="toggle-switch-switch"></span>
                                        </label>
                                    </div>
                                    <div onClick={this.handleClockInOut}>Clock In/Out</div>
                                </div>
                                {showCreareShift &&
                                    <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={<Tooltip id="edit">Create Shift</Tooltip>} >
                                        <div className="display_row align-center btn-new-event button-resp"
                                            style={{ opacity: showCreareShift ? 1 : 0.2 }}
                                            onClick={this.createShift}>
                                            <Image src={require('../../assets/images/roster/icon-create-shift.png')}
                                                className="icon-plus" />
                                        </div>
                                    </OverlayTrigger>
                                }
                                {showCreareShift &&
                                    <div className="display_row align-center btn-new-event button-not-resp"
                                        style={{ opacity: showCreareShift ? 1 : 0.2, marginLeft: 0 }}
                                        onClick={this.createShift}>
                                        <Image src={require('../../assets/images/roster/icon-create-shift.png')}
                                            className="icon-plus" />
                                        <div>Create Shift</div>
                                    </div>
                                }
                                <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={<Tooltip id="edit">View Staff Hours</Tooltip>} >
                                    <div className="display_row align-center btn-new-event button-resp"
                                        onClick={this.createStaffHours}>
                                        <Image src={require('../../assets/images/roster/icon-hours.png')}
                                            className="icon-plus" />
                                    </div>
                                </OverlayTrigger>
                                <div className="display_row align-center btn-new-event button-not-resp"
                                    onClick={this.createStaffHours}>
                                    <Image src={require('../../assets/images/roster/icon-hours.png')}
                                        className="icon-plus" />
                                    <div>View Staff Hours</div>
                                </div>

                                <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={<Tooltip id="edit">Create New Roster</Tooltip>} >
                                    <div className="display_row align-center btn-new-event button-resp"
                                        style={{ opacity: (this.state.dataCreateStaff.length > 0 && this.state.flagTab !== 0 ? 1 : 0.2), cursor: this.state.dataCreateStaff.length ? 'pointer' : 'not-allowed' }}
                                        onClick={this.state.flagTab !== 0 ? this.toggleCreateRoster : null} >
                                        <Image src={require('../../assets/images/icon-plus.png')}
                                            className="icon-plus" />
                                    </div>
                                </OverlayTrigger>

                                <div className="display_row align-center btn-new-event button-not-resp"
                                    onClick={this.state.flagTab !== 0 ? this.toggleCreateRoster : null}
                                    style={{ opacity: (this.state.dataCreateStaff.length > 0 && this.state.flagTab !== 0 ? 1 : 0.2), cursor: this.state.dataCreateStaff.length ? 'pointer' : 'not-allowed' }}>
                                    <Image src={require('../../assets/images/icon-plus.png')}
                                        className="icon-plus" />
                                    <div>Create New Roster</div>
                                </div>

                                <OverlayTrigger ref={"OverlayTriggerExport1"} placement="bottom" trigger={"click"} rootClose overlay={
                                    <Tooltip id="edit" bsClass="fade in tooltip bottom popup-menu-roster-export">
                                        <CSVLink
                                            data={csvData.data}

                                            headers={csvData.headers}
                                            newLineSeparator={`\r\n`}
                                            filename={this.state.flagTab === 1 ? ('Week' + moment(this.state.arrDay[0]).isoWeek() + '.' + moment(this.state.arrDay[0]).format('YYYY') + '.csv') : moment(this.state.onlyDay).format('DD-MM-YYYY') + '.csv'}
                                            onClick={this.totalhours}>
                                            Export CSV File
                                        </CSVLink>
                                        <PrintButton id={this.state.flagTab === 1 ? "week-view" : "day-view"}
                                            onStartEnd={() => { this.setState({ isLoadingExport: !this.state.isLoadingExport }) }}
                                            headerClass={"header-roster"}
                                            colItem={this.state.flagTab === 1 ? 1 : 2}
                                            nameSave={this.state.flagTab === 1 ? ('Week' + moment(this.state.arrDay[0]).isoWeek() + '.' + moment(this.state.arrDay[0]).format('YYYY')) : moment(this.state.onlyDay).format('DD-MM-YYYY')}
                                            className={"export"}
                                            label={"Export PDF"}
                                            onLogout={this.onLogout}
                                        />
                                    </Tooltip>
                                } >
                                    <div className="display_row align-center btn-new-event button-resp"
                                        style={{ position: 'relative', opacity: (this.state.rosterList && memberList ? 1 : 0.2), cursor: (this.state.rosterList && memberList) ? 'pointer' : 'not-allowed' }}>
                                        <Image src={require('../../assets/images/roster/icon-export.png')}
                                            className="icon-plus" />
                                    </div>
                                </OverlayTrigger>

                                <OverlayTrigger ref={"OverlayTriggerExport2"} placement="bottom" trigger={"click"} rootClose overlay={
                                    <Tooltip id="edit" bsClass="fade in tooltip bottom popup-menu-roster-export">
                                        <CSVLink
                                            data={csvData.data}
                                            headers={csvData.headers}
                                            className={"header-roster"}
                                            newLineSeparator={`\r\n`}
                                            filename={this.state.flagTab === 1 ? ('Week' + moment(this.state.arrDay[0]).isoWeek() + '.' + moment(this.state.arrDay[0]).format('YYYY') + '.csv') : moment(this.state.onlyDay).format('DD-MM-YYYY') + '.csv'}
                                            onClick={this.onLogout}>
                                            Export CSV File
                                        </CSVLink>
                                        <PrintButton id={this.state.flagTab === 1 ? "week-view" : "day-view"}
                                            onStartEnd={() => { this.setState({ isLoadingExport: !this.state.isLoadingExport }) }}
                                            headerClass={"header-roster"}
                                            colItem={this.state.flagTab === 1 ? 1 : 2}
                                            nameSave={this.state.flagTab === 1 ? ('Week' + moment(this.state.arrDay[0]).isoWeek() + '.' + moment(this.state.arrDay[0]).format('YYYY')) : moment(this.state.onlyDay).format('DD-MM-YYYY')}
                                            className={"export"}
                                            label={"Export PDF"}
                                            onLogout={this.onLogout}
                                        />
                                    </Tooltip>
                                } >
                                    <div className="display_row align-center btn-new-event button-not-resp"
                                        style={{ position: 'relative', opacity: (this.state.rosterList && memberList ? 1 : 0.2), cursor: (this.state.rosterList && memberList) ? 'pointer' : 'not-allowed' }}>
                                        <Image src={require('../../assets/images/roster/icon-export.png')}
                                            className="icon-plus" />
                                        <div>Export</div>
                                    </div>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div>
                        <Modal
                            show={this.state.isRemoveMember}
                            onHide={this.closeModal}
                            className="fix-modal remove-user"
                            >
                            <Modal.Body>            
                                <Image
                                className="logo"
                                src={require("../../assets/images/icon-member.png")}
                                />            
                                <div className="content" style={{ margin: "40px 15px 30px ",fontSize:"18px"}}>
                                Please create atleast one shift before creating the roster             
                                </div>
                            </Modal.Body>
                            </Modal>
                        </div>
                        <div>
                            <Modal show={this.state.isClockedOut} onHide={this.closeClockModal} id="popup-clock-options" className='clock-in-popup'>
                                <Modal.Body>                    
                                    <div>
                                        <div className="title-large">Deactivate Clock in/out</div>  
                                        <div className="content-roster-model" style={{ margin: `20px 0` }}>Please confirm that you would like to deactivate clock in/out? This will remove the feature from staff apps and you will revert back to scheduled hours only.</div>
                                        <button type="button"
                                            onClick={() => this.toggleClockOut()}
                                            className="btn-linear shadow clock-button"
                                            style={{ width: 240, height: 50, margin: `50px 0 0 0 ` }}
                                        >
                                            CONFIRM
                                        </button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                        <div className="member-view display_col "
                            style={{
                                // height: 'calc(100% - 122px)',
                                justifyContent: 'flex-start'
                            }}>
                            <div className={`header-roster ${this.state.headerRosterScroll ? 'scroll-active' : ''}`}>
                                <div className="display_row full_w align-between date-header">
                                    {(this.state.arrDay && this.state.arrDay.length > 0 && this.state.flagTab === 1) &&
                                        <div className="col-sm-6 time-scroll switch-week align-between justify-center display_row">
                                            <i className="fa fa-angle-left" onClick={() => this.changeWeek(-1)} />
                                            {(moment(this.state.arrDay[0]).format('MMM Do') + '-' + moment(this.state.arrDay[this.state.arrDay.length - 1]).format('MMM Do')).toUpperCase()}
                                            <i className="fa fa-angle-right" onClick={() => this.changeWeek(1)} />
                                        </div>
                                    }
                                    {this.state.flagTab === 0 &&
                                        <div className="col-sm-6 time-scroll switch-week align-between justify-center display_row">
                                            <i className="fa fa-angle-left" onClick={() => this.changeDay(-1)} />
                                            {moment(this.state.onlyDay).format('dddd, MMM Do').toUpperCase()}
                                            <i className="fa fa-angle-right" onClick={() => this.changeDay(1)} />
                                        </div>
                                    }
                                    <div className="col-sm-6 display_row tab-view" style={{ minWidth: 0 }}>
                                        {dataTab.map((key, idx) => {
                                            return (
                                                <div key={idx} className={"tab-item " + (this.state.flagTab === idx ? 'active' : '')}
                                                    onClick={() => this.changeTab(idx)}>
                                                    {key}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                {this.state.arrDay && this.state.arrDay.length > 0 && this.state.flagTab === 1 &&
                                    <div className="date-view display_row" id="date-view" style={{ marginBottom: 7 }}>
                                        <div className="day-view" style={{ cursor: "pointer" }}
                                            onClick={this.resetWeek}>{moment().format('DD/MM/YYYY')}</div>
                                        {this.state.arrDay && this.state.arrDay.map((key, idx) => {
                                            return (
                                                <div key={idx} className="day-view">
                                                    <div className={`${moment(key).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY') ? 'todayDate' : ''}`}>
                                                        {moment(key).format('ddd').toUpperCase()}
                                                    </div>
                                                    <div className={`${moment(key).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY') ? 'todayDate' : ''}`}>
                                                        {moment(key).format('MMM-DD').toUpperCase()}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                            {this.state.flagTab === 1 &&
                                <WeekView arrDay={this.state.arrDay}
                                    ref="WeekView"
                                    user={MemberId}
                                    resultFilter={resultFilter}
                                    memberList={this.state.memberList}
                                    isAdmin={isAdmin && isAdmin.length > 0}
                                    isOwner={isOwner && isOwner.length > 0}
                                    checkLogin={checkLogin}
                                    createShift={this.createShift}
                                    resetWeek={this.resetWeek}
                                    infoRoster={this.toggleInfo}
                                    getDataStaffChoose={this.getDataStaffChoose}
                                    onLoadingChange={this.state.isloading}
                                />
                            }
                            {this.state.flagTab === 0 &&
                                <DayView onlyDay={this.state.onlyDay}
                                    ref="DayView"
                                    shiftList={this.state.shiftList}
                                    admin={isAdmin && isAdmin.length > 0}
                                    owner={isOwner && isOwner.length > 0}
                                    memberList={this.state.memberList}
                                    rosterList={this.state.rosterList}
                                    memberId={MemberId}
                                    popupInfo={this.popupInfo}
                                    checkLogin={checkLogin}
                                    changeDay={this.changeDay}
                                    infoRoster={this.toggleInfo}
                                    createRosterStaff={this.toggleCreateRoster}
                                    removeRoster={(id) => this._removeRoster(id, myTeam)}
                                    getDataStaffChoose={this.getDataStaffChoose}
                                />
                            }
                        </div>
                    </div>
                </div>
                {this.state.flagCreate &&
                    <CreateRoster
                        show={this.state.flagCreate}
                        flagTab={this.state.flagTab}
                        shiftList={this.state.shiftList}
                        arrMemberCreate={this.state.arrMemberCreate}
                        memberList={this.state.memberList}
                        dataStaff={this.state.dataCreateStaff}
                        addRoster={this.addRoster}
                        onlyDay={this.state.onlyDay}
                        createShift={this.createShift}
                        handleClose={this.toggleCreateRoster} />
                }
                {this.state.dataInfo &&
                    <InfoRoster
                        show={this.state.dataInfo}
                        shiftList={this.state.shiftList}
                        clockList={this.state.clockList}
                        editRoster={this.editRoster}
                        createShift={this.createShift}
                        handleClose={this.toggleInfo}
                        removeRoster={this._removeRoster}
                        getRosterClock={this._getRosterClock}
                    />
                }
                <ConfirmRoster show={this.state.flagConfirm}
                    handleClose={this.toggleConfirmRoster} />
                <ActivateClock show={this.state.isClockEnabled && this.state.flagActivateClock}
                    handleClose={this.toggleActivateClock}
                    teamActions={this.props.teamActions}
                    idTeam={this.state.idTeam}
                    address={this.state.address}
                    radius={this.state.radius}
                    lat={this.state.lat}
                    lng={this.state.lng}
                />
                    <div>
                        <Modal
                            show={this.state.isCreateNewRosterShift}
                            onHide={this.closeCreateRosterModal}
                            className="fix-modal remove-user"
                            >
                            <Modal.Body>            
                                <Image
                                className="logo"
                                src={require("../../assets/images/icon-member.png")}
                                />            
                                <div className="content" style={{ margin: "40px 15px 30px ",fontSize:"18px" }}>
                                Please choose at least one staff before creating the roster             
                                </div>
                            </Modal.Body>
                            </Modal>
                     </div>
            </div>
        )
    }
}

class CreateRoster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            messageRoster: '',
            slideIndex: 0,
            isSubmit: false,
            isLoading: false,
            isLoadingConfirm: false,
            isLoadingConfirmAndSend: false,
            dataStaffDay: [],
            fromTime: '00:00',
            toTime: '00:00',
            shiftID: "",
            showDataFormDef: false
        };
        this.dataFormDef = [
            {
                type: 'TIME',
                key: "timeStart",
                id: "timeStart",
                class: "rc-time-picker-input",
                validator: 'isRequired',
                def_value: '00:00',
                feedback: false,
                ignoreFormGroup: true,
            },
            {
                type: 'TIME',
                key: "timeEnd",
                id: "timeEnd",
                class: "rc-time-picker-input",
                def_value: '00:00',
                validator: 'isRequired',
                feedback: false,
                ignoreFormGroup: true,
            }
        ];

        //Shift note UI :

        // this.noteFormDef = [
        //     {
        //         type: 'TEXT',
        //         key: "note",
        //         id: "note",
        //         placeholder:"Shift Note",
        //         def_value: '',
        //         // def_value:'TrangHoang',
        //         validator: 'isNull',
        //         feedback: false,
        //         maxLength: 20
        //     }
        // ];
        this.shiftNote = [{
            type: 'TEXT',
            key: "note",
            id: "note",
            placeholder:"Shift Note",
            def_value: this.props.show.note ? this.props.show.note : '',
            validator: 'isNull',
            feedback: false,
            maxLength: 20
        }]
        this.timeAndNoteFormDef = [
            {
                type: 'TIME',
                key: "timeStart",
                id: "timeStart",
                class: "rc-time-picker-input1",
                validator: 'isRequired',
                def_value: '00:00',
                feedback: false,
                ignoreFormGroup: true,
            },
            {
                type: 'TIME',
                key: "timeEnd",
                id: "timeEnd",
                class: "rc-time-picker-input2",
                def_value: '00:00',
                validator: 'isRequired',
                feedback: false,
                ignoreFormGroup: true,
            },

            // def_value:'TrangHoang',
        ];

        this.slideIndex = 0;
    }

    _handleClose = () => {
        if (this.props.handleClose) this.props.handleClose();
    };

    _createShift = () => {
        if (this.props.createShift) this.props.createShift();
    };

    changeTimeToNumber = (time) => {
        let formatTime = ''
        if (typeof (time) === 'object') {
            formatTime = moment(time).format("HH:mm")
        }
        else {
            formatTime = time
        }
        let a = time.split(':');
        a = parseInt(a[0], 10) + a[1] / 60;
        //a = parseInt(a[0],10) + a[1];
        return parseFloat(a);
    };

    _createNewShift = (flag) => {

        let dt = this.refs['CForm'].formValue();


        let params = {
            name: 'CUSTOM',
            color: 'rgb(49, 204, 188)',
            start: this.changeTimeToNumber(dt.timeStart.value),
            end: this.changeTimeToNumber(dt.timeEnd.value)
        };


        Api.put('/shift/add', params)
            .then(response => {
                if (response.code && response.code == 200) {
                    this.state.shiftID = response.data.id;
                    this.addRoasterDetails(flag);
                } else {
                    this.setState({
                        messageRoster: response.data.message,
                        isLoading: false
                    });
                    this.isSubmit = false;
                }
            });
    }

    addRoasterDetails = (flag) => {
        let { dataStaff, shiftList, flagTab } = this.props;
        let dataCreate = [];
        let dt = ''
        let note = ''
        if (this.refs['CForm']) {
            dt = this.refs['CForm'].formValue();
            note = dt.note.value;
        }

        if (flagTab === 1) {
            for (let i in dataStaff) {
                if (dataStaff[i].user && dataStaff[i].user.staff && dataStaff[i].user.staff.id) {
                    let data = {};
                    data.userID = dataStaff[i].user.staff.id;
                    if (this.slideIndex !== shiftList.length) {
                        data.shifts = [{ shiftID: shiftList[this.slideIndex].id, time: moment(dataStaff[i].date).valueOf(), date_string: dataStaff[i].date.split('T')[0] }];
                    } else {
                        data.shifts = [{ shiftID: this.state.shiftID, time: moment(dataStaff[i].date).valueOf(), date_string: dataStaff[i].date.split('T')[0] }];
                    }
                    dataCreate.push(data);
                }
            }
        }
        else if (flagTab === 0) {
            dataCreate = [...this.state.dataStaffDay];
            if(this.state.shiftID){                
                dataCreate.filter(i => i.shifts[0].shiftID =  this.state.shiftID);
            }
            else{
                dataCreate.filter(i => i.shifts[0].shiftID = shiftList[this.slideIndex].id);                
            }
        }

        if (this.props.addRoster) {
            this.props.addRoster(dataCreate, flag, note, (err, data) => {
                if (err) {
                    this.setState({
                        messageRoster: err.message,
                        isLoading: false
                    });
                    this.isSubmit = false;
                } else if (data) {
                    this.isSubmit = false;
                    this.setState({
                        isLoading: false
                    })
                }
            });
        }
    }

    _addRoster = (flag) => {
        let { dataStaff, shiftList, flagTab } = this.props;
        if (this.isSubmit || this.state.isLoading) return;
        this.setState({
            isLoading: true,
        });

        if (flag === 'N') {
            this.setState({ isLoadingConfirm: true });
          } else if (flag === 'Y') {
            this.setState({ isLoadingConfirmAndSend: true });
        }


        if (this.isSubmit) return;
        this.isSubmit = true;


        if (this.slideIndex === shiftList.length) {

            this._createNewShift(flag);
        } else {
            this.addRoasterDetails(flag);
        }
    };

    changeTimeToSecond = (time) => {
        let a = time.toString().split('.');
        let second = Math.round(parseFloat('0.' + (a[1] || 0)) * 60);
        second = second <= 9 ? '0' + parseInt(second, 10) : parseInt(second, 10);
        a = a[0] + ':' + second;
        return a;
    };

    addStaffCreate = (key) => {
        let { onlyDay, shiftList } = this.props;
        let dataStaffDay = [...this.state.dataStaffDay];
        if (!dataStaffDay || dataStaffDay.length === 0) {
            let data = {};
            data.userID = key.user.id;
            if (this.slideIndex !== shiftList.length) {
                data.shifts = [{ shiftID: shiftList[this.slideIndex].id, time: moment(onlyDay).valueOf() }];
            } else {
                data.shifts = [{ shiftID: this.state.shiftID, time: moment(onlyDay).valueOf()}];
            }          
            dataStaffDay.push(data);
        }
        else {
            let remove = false;
            for (let i in dataStaffDay) {
                if (dataStaffDay[i].userID === key.user.id) {
                    dataStaffDay.splice(i, 1);
                    remove = true;
                    break;
                }
            }
            if (!remove) {
                let data = {};
                data.userID = key.user.id;
                if (this.slideIndex !== shiftList.length) {
                    data.shifts = [{ shiftID: shiftList[this.slideIndex].id, time: moment(onlyDay).valueOf() }];
                } else {
                    data.shifts = [{ shiftID: this.state.shiftID, time: moment(onlyDay).valueOf()}];
                } 
                dataStaffDay.push(data);
            }
        }
        this.setState({
            dataStaffDay: dataStaffDay
        })
    };

    render() {
        let { dataStaff, shiftList, flagTab, arrMemberCreate, onlyDay } = this.props;
        let dataId = [];
        const showCreateShift = !shiftList || (shiftList && shiftList.length <= 0);

        return (
            <Modal show={this.props.show} onHide={this._handleClose} id="popup-create-roster">
                <Modal.Body>
                    <div className="title">CREATE ROSTER</div>
                    <div className="display_col align-between">
                        {flagTab === 1 &&
                            <div className="drop-staff display_row btn-linear-invitew margin-0-auto"
                                style={{ height: 110, overflowX: 'auto', width: "auto" }}>
                                {dataStaff && dataStaff.map((key, idx) => {
                                    if (dataId.indexOf(key.user.staff.id) !== -1) return null;
                                    dataId.push(key.user.staff.id);
                                    return (
                                        <div className="staff-view" key={"staff-iten" + idx}>
                                            <Image className="avatar-staff" src={(key.user.staff.avatar) ? key.user.staff.avatar : Config.avaDef} style={{ width: '100%', height: '100%' }} />
                                            <span className="name-staff">{key.user.staff.fullName}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        {flagTab === 0 &&
                            <div style={{ textAlign: 'left' }} className='open-sans-regular'>Please choose staffs to create roster of the {moment(onlyDay).format('dddd, MMM Do')}</div>
                        }
                        {flagTab === 0 &&
                            <div className="drop-staff display_row btn-linear-invitew full_w" style={{ height: 105, overflowX: 'auto' }}>
                                {arrMemberCreate && arrMemberCreate.map((key, idx) => {
                                    const isCheck = this.state.dataStaffDay.filter(i => i.userID === key.user.id);
                                    return (
                                        <div className="staff-view" key={"staff-iten" + idx} onClick={() => this.addStaffCreate(key)}>
                                            <Image className="avatar-staff" src={(key.user.avatar) ? key.user.avatar : Config.avaDef}
                                                style={{ width: '100%', height: '100%' }} />
                                            <span className="name-staff">{key.user.fullName}</span>
                                            {isCheck && isCheck.length > 0 && <i className="fa fa-check" />}
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        {!showCreateShift &&
                            <Carousel
                                afterSlide={(slideIndex) => {
                                    this.slideIndex = slideIndex;
                                    if (slideIndex === shiftList.length) {
                                        this.setState({ showDataFormDef: true })
                                    } else {
                                        this.setState({ showDataFormDef: false })
                                    }
                                }}
                                renderCenterLeftControls={({ previousSlide, currentSlide }) => (
                                    currentSlide === 0 ? null : <i className="fa fa-angle-left" onClick={previousSlide} />
                                )}
                                renderCenterRightControls={({ nextSlide, currentSlide }) => (
                                    currentSlide === ((shiftList && shiftList.length + 1) ? shiftList.length : 0) ? null : <i className="fa fa-angle-right" onClick={nextSlide} />
                                )}
                            // onChange={(slideIndex) => {
                            //     this.slideIndex = slideIndex
                            // }}
                            >
                                {shiftList && shiftList.map((key, idx) => {
                                    let off = false;
                                    if (key.name === 'OFF' && key.start === 0 && key.end === 0) {

                                        // if (key.name === 'OFF') {
                                        // off=false;
                                        off = true;
                                    }
                                    return (
                                        <div key={idx} className="display_col">
                                            {off && <span className="name-shift txt-off">OFF</span>}
                                            {!off && <span className="name-shift">{key.name}</span>}
                                            {!off && <span
                                                className="time-shift">{this.changeTimeToSecond(key.start)} - {this.changeTimeToSecond(key.end)}</span>}
                                        </div>
                                    )
                                })}
                                {/* <div  className="display_col">
                                           <span className="name-shift txt-off">OFF</span>
                                             <span className="name-shift">OFF</span>
                                        
                                        </div> */}
                                <div className="display_col">
                                    <span className="name-shift txt-off">
                                        <span className="name-shift" >Manual Input</span>
                                    </span>
                                    {/*<span className="time-shift">*/}
                                    {/*<CForm data={this.dataFormDef} ref="CForm" />*/}
                                    {/*</span>*/}
                                </div>
                            </Carousel>
                        }

                        {this.state.messageRoster !== '' && (
                            <div className="register_error" style={{ width: '100%', bottom: 70, height: 40, textAlign: 'center', alignItems: 'flex-end' }}>
                                {this.state.messageRoster}
                            </div>
                        )}
                        {showCreateShift && (
                            <div className="display_row justify-center flex_wrap" style={{ position: 'relative', width: '100%', alignItems: 'center', justifyContent: 'center', padding: '20px 0' }}>
                                
                                <div className="col-sm-12 col-md-12 open-sans-regular" style={{ fontSize: 18 }}>Click below to create your Custom Shift</div>
                            </div>
                        )}
                        <div>
                            {this.state.showDataFormDef && !showCreateShift && (
                                <span className="time-shift manualInput-time-shift">
                                    <CForm data={this.timeAndNoteFormDef} ref="CForm" />
                                </span>
                            )}
                        </div>
                        {/* {!this.state.showDataFormDef && !showCreateShift &&
                            <span className="name-shift">

                            </span>
                        } */}
                        {!showCreateShift &&
                            <Button className="menu-left-action button-fonts centered-button btn-linear shadow btn-linear-invite" style={{ width: 'auto' }}
                                onClick={() => { this._addRoster('N') }}>
                                {/* onClick={this._addRoster}> */}
                                CONFIRM
                                {this.state.isLoadingConfirm && this.state.isLoading &&
                                    <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                                        <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30} />
                                    </div>
                                }
                            </Button>
                        }
                        {!showCreateShift &&
                            <Button className="menu-left-action button-fonts centered-button btn-linear-orange shadow btn-linear-invite" style={{ width: 'auto' }}
                                onClick={() => { this._addRoster('Y') }}>
                                CONFIRM AND SEND NOTIFICATION
                                {this.state.isLoadingConfirmAndSend && this.state.isLoading &&
                                    <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                                        <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30} />
                                    </div>
                                }
                            </Button>
                        }
                        {showCreateShift &&
                            <Button className="menu-left-action btn-linear shadow btn-linear-invite" style={{ width: 'auto' }}
                                onClick={this._createShift}>
                                CREATE CUSTOM SHIFT
                            </Button>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

class InfoRoster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            messageRoster: '',
            slideIndex: 0,
            isSubmit: false,
            isLoading: false,
            flagEdit: false,
            showDataFormDef: false,
            clocks: this.getClockList() || [],
            clock_in_time: '',
            clock_out_time: '',
            note:'',
        };
        this.slideIndex = 0;
        this.dataFormDef = [
            {
                type: 'TIME',
                key: "timeStart",
                id: "timeStart",
                class: "rc-time-picker-input",
                validator: 'isRequired',
                def_value: '00:00',
                feedback: false,
                ignoreFormGroup: true,
            },
            {
                type: 'TIME',
                key: "timeEnd",
                id: "timeEnd",
                class: "rc-time-picker-input",
                def_value: '00:00',
                validator: 'isRequired',
                feedback: false,
                ignoreFormGroup: true,
            }
        ];
        // this.noteFormDef = [
        //     {
        //         type: 'TEXT',
        //         key: "note",
        //         id: "note",
        //         placeholder:"Shift Note",
        //         def_value: this.props.show.note ? this.props.show.note : '',
        //         // def_value:'TrangHoang',
        //         validator: 'isNull',
        //         feedback: false,
        //         maxLength: 20
        //     }
        // ];
        this.timeAndNoteFormDef = [
            {
                type: 'TIME',
                key: "timeStart",
                id: "timeStart",
                class: "rc-time-picker-input",
                validator: 'isRequired',
                def_value: '00:00',
                feedback: false,
                ignoreFormGroup: true,
            },
            {
                type: 'TIME',
                key: "timeEnd",
                id: "timeEnd",
                class: "rc-time-picker-input",
                def_value: '00:00',
                validator: 'isRequired',
                feedback: false,
                ignoreFormGroup: true,
            },
            // {
            //     type: 'TEXT',
            //     key: "note",
            //     id: "note",
            //     placeholder:"Shift Note",
            //     def_value: this.props.show.note ? this.props.show.note : '',
            //     // def_value:'TrangHoang',
            //     validator: 'isNull',
            //     feedback: false,
            //     maxLength: 20
            // }
        ];

    }

    changeTimeToNumber = (time) => {
        let formatTime = ''
        console.log(time, typeof (time))
        if (typeof (time) === 'object') {
            formatTime = moment(time).format("hh:mm")
        }
        else {
            formatTime = time
        }
        let a = time.split(':');
        a = parseInt(a[0], 10) + a[1] / 60;
        //a = parseInt(a[0],10) + a[1];
        return parseFloat(a);
    };

    _handleClose = () => {
        if (this.props.handleClose) this.props.handleClose();
    };

    _createNewShift = () => {

        let dt = this.refs['CForm'].formValue();
        let params = {
            name: 'CUSTOM',
            color: 'transparent',
            start: this.changeTimeToNumber(dt.timeStart.value),
            end: this.changeTimeToNumber(dt.timeEnd.value)
        };

        Api.put('/shift/add', params)
            .then(response => {
                if (response.code && response.code == 200) {
                    this.state.shiftID = response.data.id;
                    this._updateRoaster();
                } else {
                    this.setState({
                        messageRoster: response.data.message,
                        isLoading: false
                    });
                    this.isSubmit = false;
                }
            });
    }

    _editRoster = () => {
        let { shiftList } = this.props;

        if (shiftList.length === this.slideIndex) {
            this._createNewShift()
        }
        this._updateRoaster();
    };

    _updateRoaster = () => {
        let { show, shiftList } = this.props;
        let params = {
            id: show.id
        };

        if (this.slideIndex < shiftList.length && shiftList[this.slideIndex].id === 'OFF') {
            params.isOff = 'Y';
        }
        else {
            params.isOff = 'N';
            if (shiftList.length === this.slideIndex)
                params.shift = this.state.shiftID;
            else
                params.shift = shiftList[this.slideIndex].id;
        }


        //let dt = this.refs['CForm'].formValue();
        // params.note = dt.note.value;

        if (this.props.editRoster) this.props.editRoster(params, (err, data) => {
            this.isSubmit = false;
        });
        else {
            let dt = this.refs['CForm'].formValue();
            params.note = dt.note.value;
        }
    }

    _rosterRemove = () => {         
        this.setState({ isUpdateRemoveTeamConfirmed: true });
      };

    _removeRoster = () => {
        // if(this.isSubmit) return;
        // this.isSubmit = true;
        let { show } = this.props;

        if (this.props.removeRoster) this.props.removeRoster(show.id, (err, data) => {
            if (err) {
                // Config.alertMess(err);
            } else if (data) {
                // this.isSubmit = false;
                // this._handleClose();
            }
        });
    };

    changeTimeToSecond = (time) => {
        let a = time.toString().split('.');
        let second = Math.round(parseFloat('0.' + (a[1] || 0)) * 60);
        second = second <= 9 ? '0' + parseInt(second, 10) : parseInt(second, 10);
        a = a[0] + ':' + second;
        return a;
    };

    createShift = () => {
        if (this.props.createShift) this.props.createShift();
    };

    getClockList = () => {
        let { show } = this.props;

        if (this.props.getRosterClock) this.props.getRosterClock(show.id, (err, data) => {
            if (err) {
                // Config.alertMess(err);
            } else if (data) {

                data.forEach((clock) => {
                    //clock.time = new Date(clock.time).toString();
                    let date = new Date(clock.time);
                    //debugger;
                    //let hours = date.getHours();
                    //let minutes = date.getMinutes();
                    // clock.time = `${hours}:${minutes}`;
                    clock.time = date.toLocaleTimeString();
                })

                let clock_in_list = data.filter(c => c.is_clock_in === true);
                let clock_out_list = data.filter(c => c.is_clock_in === false);

                let clock_in_time = clock_in_list.length > 0 ?
                    clock_in_list[0].time_string : "";
                let clock_out_time = clock_out_list.length > 0 ?
                    clock_out_list[clock_out_list.length - 1].time_string : "";

                this.setState({
                    clocks: data,
                    clock_in_time: data[0].clock_in,
                    clock_out_time: data[0].clock_out,
                    note:data[0].note,
                });
            }
        });
    }

    // _onEditRosterClick = () => {
    //     this.setState({ flagEdit: true });
    // };

    render() {
        let { show, shiftList } = this.props;
        let { flagEdit } = this.state;
        const staff = show.staff;
        const shift = show.shift;
        if (!staff) {
            this._handleClose();
        }
        let shiftOff = false;
        if ((!shift || shift.name === 'OFF' || (shift.start === 0 && shift.end === 0))) {
            shiftOff = true;
        }
        const showCreateShift = (flagEdit && (!shiftList || (shiftList && shiftList.length <= 0)));
        return (
            <div>
            <Modal show={!!show} onHide={this._handleClose} id="popup-create-roster" className="modal custom">
                <Modal.Body>
                    <div className='model-popup clock-in-edit'>
                    <div className="display_row" style={{ alignItems: "baseline" }}>
                        {flagEdit && <div className="display_row align-center back-to-roaster-info pop"
                            //style={{marginTop: 0, float: "right", color: "#31CCBC", fontSize: "15.3px"}}
                            onClick={() => this.setState({ flagEdit: false })}><img
                                src={require('../../assets/images/roster/icon-back-def.png')}
                                alt={""}
                                className="back-img"
                            />Back to Roster
                        </div>}
                        <div className="title" style={{ textAlign: "center" }}>{moment(show.time).format('dddd, MMM Do')}</div>
                        {flagEdit && <div className="back-to-roaster-info" />}
                    </div>
                    <div className="display_col align-between">
                        <div className="display_row" style={{ justifyContent: "space-between" }}>
                            <div className={`drop-staff display_row btn-linear-invitew ${flagEdit ? "margin-0-auto" : ""}`}
                                //style={{height:94, overflowX:'auto'}}
                                style={{ height: '100%', overflowX: 'auto', width: '140px' }}
                            >
                                <div className={`staff-view  ${flagEdit ? "margin-0-auto" : ""} `} >
                                    <Image className="avatar-staff" src={(staff.avatar) ? staff.avatar : Config.avaDef} style={{ width: '100%', height: '100%' }} />
                                    <span className="name-staff">{staff.fullName}</span>
                                </div>
                            </div>
                            {!flagEdit &&
                                <div className="display_row flex_wrap time">
                                    {shiftOff && <span className="name-shift txt-off">OFF</span>}
                                    {!shiftOff && <span className="name-shift time-title">Start time</span>}
                                    {!shiftOff && <span className="name-shift time-title">End time</span>}
                                    {!shiftOff && <span className="time-shift time-value">{this.changeTimeToSecond(shift.start)}</span>}
                                    {!shiftOff && <span className="time-shift time-value">{this.changeTimeToSecond(shift.end)}</span>}
                                </div>
                            }
                            {!flagEdit && <div className="" style={{ width: '25%', padding: '5px' }}
                            >
                                {/*{!flagEdit &&*/}
                                {/*<Button className="menu-left-action btn-linear shadow btn-linear-invite"*/}
                                {/*        //style={{width:'auto'}}*/}
                                {/*        style={{height:'45px'}}*/}
                                {/*        onClick={() => this.setState({flagEdit: !flagEdit})}>*/}
                                {/*    EDIT*/}
                                {/*</Button>*/}
                                {/*}*/}
                                {!flagEdit &&
                                    <Button className="menu-left-action button-fonts edit  btn-linear shadow btn-linear-invite info-roster-button" //style={{width:'auto'}
                                        //style={{height: 'auto'}}
                                        
                                        onClick={() => { this.setState({ flagEdit: true }) }}>
                                        EDIT
                                    </Button>
                                }
                                {!flagEdit &&
                                    <Button className="menu-left-action delete  button-fonts  btn-linear-orange shadow btn-linear-invite info-roster-button"
                                        //style={{width:'auto'}}
                                        
                                        onClick={this._rosterRemove}>
                                        DELETE
                                    </Button>
                                }
                            </div>}
                        </div>
                        <Carousel
                            style={{ display: flagEdit && !showCreateShift ? 'block' : 'none', position: flagEdit && !showCreateShift ? 'relative' : 'absolute' }}
                            afterSlide={(slideIndex) => {
                                this.slideIndex = slideIndex;
                                if (slideIndex === shiftList.length) {
                                    this.setState({ showDataFormDef: true })
                                } else {
                                    this.setState({ showDataFormDef: false })
                                }
                            }}
                            renderCenterLeftControls={({ previousSlide, currentSlide }) => (
                                currentSlide === 0 ? null : <i className="fa fa-angle-left" onClick={previousSlide} />
                            )}
                            renderCenterRightControls={({ nextSlide, currentSlide }) => (
                                currentSlide === ((shiftList && shiftList.length + 1) ? shiftList.length : 0) ? null : <i className="fa fa-angle-right" onClick={nextSlide} />
                            )}
                        // onChange={(slideIndex) => {
                        //     this.slideIndex = slideIndex
                        // }}
                        >
                            {shiftList && shiftList.map((key, idx) => {
                                let off = false;
                                if (key.name === 'OFF') {
                                    off = true;
                                }
                                return (
                                    <div key={idx} className="display_col">
                                        {off && <span className="name-shift txt-off">OFF</span>}
                                        {!off && <span className="name-shift">{key.name}</span>}
                                        {!off && <span
                                            className="time-shift">{this.changeTimeToSecond(key.start)} - {this.changeTimeToSecond(key.end)}</span>}
                                    </div>
                                )

                            })}
                            <div className="display_col">

                                <span className="name-shift txt-off">

                                    <span className="name-shift" ref="CForm" >Manual Input</span>
                                </span>
                                {/*<span className="time-shift">*/}
                                {/*<CForm data={this.dataFormDef} ref="CForm" />*/}
                                {/*</span>*/}
                            </div>
                        </Carousel>
                        {/*{!flagEdit &&*/}
                        {/*    <div className="display_row flex_wrap justify-center" style={{margin:'20px 0'}}>*/}
                        {/*        {shiftOff && <span className="name-shift txt-off">OFF</span>}*/}
                        {/*        {!shiftOff && <span className="name-shift col-sm-6 col-md-6">Start time</span>}*/}
                        {/*        {!shiftOff && <span className="name-shift col-sm-6 col-md-6">End time</span>}*/}
                        {/*        {!shiftOff && <span className="time-shift col-sm-6 col-md-6">{this.changeTimeToSecond(shift.start)}</span>}*/}
                        {/*        {!shiftOff && <span className="time-shift col-sm-6 col-md-6">{this.changeTimeToSecond(shift.end)}</span>}*/}
                        {/*    </div>*/}
                        {/*}*/}
                        {/*{!flagEdit &&*/}
                        {/*<Button className="menu-left-action btn-linear shadow btn-linear-invite" style={{width:'auto'}}*/}
                        {/*        onClick={()=>{this.setState({flagEdit: !flagEdit})}}>*/}
                        {/*    EDIT*/}
                        {/*</Button>*/}
                        {/*}*/}
                        {/*{!flagEdit &&*/}
                        {/*<Button className="menu-left-action btn-linear-orange shadow btn-linear-invite" style={{width:'auto'}}*/}
                        {/*        onClick={this._removeRoster}>*/}
                        {/*    DELETE*/}
                        {/*</Button>*/}
                        {/*}*/}
                        {this.state.messageRoster !== '' && (
                            <div className="register_error" style={{ width: '100%', bottom: 150, height: 40, textAlign: 'center', alignItems: 'flex-end' }}>
                                {this.state.messageRoster}
                            </div>
                        )}
                        {!flagEdit && this.state.clocks.length !== 0 &&
                            <div className="info-details">
                                <div className="custom-modal-title">Clock In/Out</div>
                                <div className="display_row" style={{ margin: "10px 0 37px 0" }}>
                                    <div className="name-shift info-text">
                                        Clock In:
                                    </div>
                                    <div className="clock-time info-text">
                                        {
                                            //this.state.clocks[0].time_string
                                            this.state.clock_in_time
                                        }
                                    </div>
                                    <div className="clock-alert info-radius">
                                        {!this.state.clocks[0].clock_in_radius &&
                                        <i className="fa fa-bell" style={{ margin: "0 10px", color: '#EB6965' }}></i>
                                        }
                                       {this.state.clocks[0].clock_in_radius ? '' : 'Clock is not detected within active zone'}
                                           
                                    </div>
                                </div>
                                <div className="display_row" style={{ margin: "10px 0 22px 0" }}>
                                    <div className="name-shift info-text">
                                        Clock Out:
                                    </div>
                                    <div className="clock-time info-text">
                                        {
                                            //this.state.clocks[this.state.clocks.length -1].time_string
                                            this.state.clock_out_time
                                        }
                                    </div>
                                    <div className="clock-alert info-radius">
                                        {!this.state.clocks[0].is_in_radius && this.state.clocks[0].clock_out &&
                                        <i className="fa fa-bell" style={{ margin: "0 10px", color: '#EB6965' }}></i>
                                        }
                                        {this.state.clocks[0].clock_out && !this.state.clocks[0].is_in_radius ? 'Clock is not detected within active zone' : ''}
                                    </div>
                                </div>
                            </div>
                        }
                        {!flagEdit &&
                            <div className="info-details">
                                <div className="custom-modal-title">Shift Note</div>
                        <div className="shift-note-detail">
                            {this.state.note}
                         </div>
                                </div>
                        } 
                        {!flagEdit &&
                            <Button className="menu-left-action button-fonts centered-button btn-linear shadow btn-linear-invite" style={{ width: 'auto' }}
                                onClick={this._handleClose}
                            >
                                CLOSE
                                {this.state.isLoading &&
                                    <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                                        <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30} />
                                    </div>
                                }
                            </Button>
                        }
                        {this.state.showDataFormDef && flagEdit && !showCreateShift && (
                            <span className="time-shift">
                                <CForm data={this.timeAndNoteFormDef} ref="CForm" />
                            </span>
                        )}
                        {/* {!this.state.showDataFormDef && flagEdit && !showCreateShift &&
                            <span className="name-shift">
                                <CForm data={this.noteFormDef} ref="CForm" />
                            </span>
                        } */}
                        {flagEdit && !showCreateShift &&
                            <Button className="menu-left-action button-fonts centered-button btn-linear shadow btn-linear-invite" style={{ width: 'auto' }}
                                onClick={this._editRoster}
                            >
                                CONFIRM AND SEND NOTIFICATION
                                {this.state.isLoading &&
                                    <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                                        <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30} />
                                    </div>
                                }
                            </Button>
                        }
                        {/*{!flagEdit &&*/}
                        {/*    <Button className="menu-left-action btn-linear shadow btn-linear-invite" style={{width:'auto'}}*/}
                        {/*            onClick={()=>{this.setState({flagEdit: !flagEdit})}}>*/}
                        {/*        EDIT*/}
                        {/*    </Button>*/}
                        {/*}*/}
                        {/*{!flagEdit &&*/}
                        {/*    <Button className="menu-left-action btn-linear-orange shadow btn-linear-invite" style={{width:'auto'}}*/}
                        {/*            onClick={this._removeRoster}>*/}
                        {/*        DELETE*/}
                        {/*    </Button>*/}
                        {/*}*/}
                        {showCreateShift && (
                            <div className="display_row justify-center flex_wrap" style={{ position: 'relative', width: '100%', alignItems: 'center', justifyContent: 'center', padding: '20px 0' }}>
                                <div className="col-sm-12 col-md-12" style={{ fontSize: 18 }}>Custom Shift</div>
                                <div className="col-sm-12 col-md-12" style={{ fontSize: 18 }}>Click below to create</div>
                            </div>
                        )}
                        {showCreateShift &&
                            <Button className="menu-left-action btn-linear shadow btn-linear-invite" style={{ width: 'auto' }}
                                onClick={this.createShift}>
                                CREATE CUSTOM SHIFT
                            </Button>
                        }
                     </div>
                   </div>
                </Modal.Body>
            </Modal>

            <Modal
            show={this.state.isUpdateRemoveTeamConfirmed}
            onHide={this.closeModal}
            id="popup-change-plan"
            className={"root-popup-change-plan remove-team-member"}
            style={{  maxWidth: "100%", margin: "auto" }}
        >
            
            <Modal.Body>
            <Image
                className="logo remove-member-logo"
                src={require("../../assets/images/register/icon-member.png")}
            />
            <div className="title">DELETE ROSTER</div>
            <div className="title1 remove-admin">Do you want to remove this roster?</div>
            <div className="button_display_row" style={{ padding: "10px 0" }}>
                
                <Button
                className="login_btn_login btn-linear-orange shadow"
                style={{ maxHeight: 50, margin: "0 5px" }}
                onClick={this._handleClose}
                >
                CANCEL
                </Button>
                <Button
                className="login_btn_login btn-linear shadow"
                style={{ maxHeight: 50, margin: "0 5px" }}
                onClick={this._removeRoster}
                >
                CONFIRM
                </Button>
            </div>
            </Modal.Body>
        </Modal>
        </div>
        )
    }
}

class DayView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateNow: moment().format(),
            dataChoose: [],
            flagEdit: false,
            isAddRosterMember:false,
        };
        this.arrMemberCreate = null;
    }

    clearDataChoose = () => {
        this.setState({
            dataChoose: []
        })
    };

    changeTimeToSecond = (time) => {
        let a = time.toString().split('.');
        let second = Math.round(parseFloat('0.' + (a[1] || 0)) * 60);
        second = second <= 9 ? '0' + parseInt(second, 10) : parseInt(second, 10);
        a = a[0] + ':' + second;
        return a;
    };

    _removeRoster = (id) => {
        if (this.props.removeRoster) this.props.removeRoster(id);
    };

    cropName = (name) => {
        if (!name) return '';
        let nameTemp = name.split(' ');
        return nameTemp[0];
    };

    _createRosterStaff = (arrMemberCreate) => {
        if (arrMemberCreate.length === 0) {
            this.setState({
                isAddRosterMember:true,
            })
        }
        else {
            if (this.props.createRosterStaff) this.props.createRosterStaff(arrMemberCreate);
        }
    };

    _infoRoster = (roster) => {
        if (this.state.flagEdit) return;
        if (this.props.infoRoster) this.props.infoRoster(roster);
    };
    closeModal = () => {
        this.setState({
            isAddRosterMember:false,
        })
    };

    render() {
        console.log('=================DAY VIEW=================');
        let { memberList, rosterList, admin, owner, memberId } = this.props;
        let arrMemberCreate = []
        let loader = true
        console.log("admin:", admin)
        console.log("owner:", owner)
        console.log("admin || owner", admin || owner)
        let resultFilter = [];
        if (rosterList && memberList && memberList.length) {
            let arrTeam = rosterList.slice(0);
            arrTeam.forEach(function (hash) {
                return function (a) {
                    if (!hash[a.shift ? a.shift.id : 'Y']) {
                        hash[a.shift ? a.shift.id : 'Y'] = { shift: a.shift || 'Y', staff: [], id: a.id, data: a, roster: [], rosterData: [] };
                        resultFilter.push(hash[a.shift ? a.shift.id : 'Y']);
                    }
                    if (a.staff && a.staff.id) {
                        hash[a.shift ? a.shift.id : 'Y'].staff.push(a.staff);
                        hash[a.shift ? a.shift.id : 'Y'].roster.push(a.id);
                        hash[a.shift ? a.shift.id : 'Y'].rosterData.push(a);
                    }
                };
            }(Object.create(null)));
            resultFilter.sort((i, j) => { return i.shift.start - j.shift.start });
            if (resultFilter && resultFilter.length) {
                loader = false
            }
            if (memberList.length) {
                loader = false
            }
            arrMemberCreate = [...memberList];
            arrMemberCreate = arrMemberCreate.filter(i => i.status === 'ACTIVE' && ((owner && i.role !== 'OWNER') || (admin && i.role === 'STAFF')));
        }
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', padding: '0 2px 10px 2px', position: 'relative' }}>
                {(admin || owner) && <div className="display_row align-right button-view" style={{ position: 'initial', marginTop: 0 }}>
                    <Button className="menu-left-action btn-linear-orange shadow"
                        onClick={() => this.setState({ flagEdit: !this.state.flagEdit })}>{this.state.flagEdit ? "CANCEL" : "EDIT"}</Button>
                    <Button className="btn-linear menu-left-action shadow" onClick={() => this._createRosterStaff(arrMemberCreate)}>ADD NEW</Button>
                </div>}
                {/* <div className="scroll full_h_1 full_w"  style={{height:'calc(100vh - 320px)'}}></div> */}
                <div className="scroll full_h_1 full_w">
                    <div id="day-view" style={{ display: 'flex', flexWrap: 'wrap', paddingBottom: 100 }}>
                        {loader &&
                            <div className="display_row align-center justify-center" style={{
                                width: '100%',
                                borderRadius: 25,
                                padding: 10,
                                position: 'absolute',
                                height: '100%'
                            }}>
                                <ReactLoading type={"spinningBubbles"} color={'#31CCBC'} height={30} width={30} />
                            </div>
                        }
                        {resultFilter && resultFilter.map((key, idx) => {
                            let totalMem = [];
                            for (let user in memberList) {
                                for (let staff in key.staff) {
                                    if ((!memberId || (memberId && memberId === memberList[user].user.id)) && memberList[user] && memberList[user].user && key.staff[staff].id === memberList[user].user.id) {
                                        totalMem.push(key.staff[staff]);
                                        for (let i = 0; i < arrMemberCreate.length; i++) {
                                            if (arrMemberCreate[i].user && arrMemberCreate[i].user.id === key.staff[staff].id) {
                                                arrMemberCreate.splice(i, 1);
                                            }
                                        }
                                    }
                                }
                            }
                            if (totalMem.length <= 0) {
                                console.log("roster not created on this day")
                                return null
                            }
                            return (
                                <div key={idx + 'roster-view'}
                                    className={"col-sm-6 col-md-6 col-sm-12"}
                                    style={{
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        // width:'100%',
                                        padding: 0,
                                        border: 'none',
                                        margin: 0,
                                        position: 'relative'
                                    }}>
                                    <div style={{
                                        width: '100%', textAlign: 'left',
                                        fontSize: 18,
                                        color: '#DDDCDB',
                                        fontFamily: 'open-sans-regular', padding: '0 0 10px'
                                    }}>
                                        {key.shift === 'Y' ? 'OFF' : (key.shift.name + ' ' + this.changeTimeToSecond(key.shift.start) + ' - ' + this.changeTimeToSecond(key.shift.end))}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                                        {key.staff && key.staff.map((keyChild, idxChild) => {
                                            let isMember = memberList.filter(i => i.user && i.user.id === keyChild.id);
                                            if (isMember.length <= 0) return null;
                                            return (
                                                <div key={"member" + idxChild} onClick={() => this._infoRoster(key.rosterData[idxChild])}
                                                    style={{
                                                        maxWidth: 93,
                                                        maxHeight: 85,
                                                        minWidth: 93,
                                                        minHeight: 85,
                                                        margin: 8,
                                                        position: "relative",
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        borderRadius: 3,
                                                        overflow: 'hidden',
                                                        width: 61,
                                                        height: 55,
                                                        marginLeft: 1,
                                                        borderWidth: 1,
                                                        display: 'flex',
                                                    }}>
                                                    {this.state.flagEdit &&
                                                        <Image src={require('../../assets/images/button_tasks_delete.png')}
                                                            className="icon-close icon-remove"
                                                            style={{ top: 5, right: 5, zIndex: 2 }}
                                                            onClick={() => this._removeRoster(key.roster[idxChild])}
                                                        />
                                                    }
                                                    <img key={"member" + idxChild}
                                                        alt=""
                                                        style={{
                                                            borderRadius: 3, overflow: 'hidden', width: 93, height: 85, objectFit: 'cover',
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            flex: 1
                                                        }}
                                                        src={keyChild.avatar ? keyChild.avatar : ''} />
                                                    <div style={{
                                                        width: 93,
                                                        height: 85,
                                                        position: 'absolute',
                                                        flex: 1,
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        backgroundColor: 'rgba(0,0,0,0.5)',
                                                    }}>
                                                    </div>
                                                    <div style={{ fontSize: 18, fontFamily: 'OpenSans-Extrabold', color: 'white', position: 'absolute' }}>
                                                        {keyChild.fullName ? this.cropName(keyChild.fullName) : ''}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    <div
                                        style={{
                                            // borderBottomColor: Config.gStyle.color_DDDCDC,
                                            borderBottomWidth: 1,
                                            width: 226,
                                            marginBottom: 20,
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div>
                        <Modal
                            show={this.state.isAddRosterMember}
                            onHide={this.closeModal}
                            className="fix-modal remove-user"
                            >
                            <Modal.Body>            
                                <Image
                                className="logo"
                                src={require("../../assets/images/icon-member.png")}
                                />            
                                <div className="content" style={{ margin: "40px 15px 30px ",fontSize:"18px"}}>
                                Create new staffs to have the roster            
                                </div>
                            </Modal.Body>
                            </Modal>
                </div>
            </div>
        )
    }
}

class ConfirmRoster extends Component {

    _handleClose = () => {
        if (this.props.handleClose) this.props.handleClose();
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={this._handleClose} id="popup-confirm-roster">
                <Modal.Body>
                    <Image src={require('../../assets/images/btn-close-popup.png')}
                        className="icon-close"
                        onClick={this._handleClose} />
                    <Image className="logo" src={require('../../assets/images/register/icon-member.png')} />
                    <div className="title">Roster Created!</div>
                    <div className="content">Roster has been created</div>
                </Modal.Body>
            </Modal>
        )
    }
}

class WeekView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataChoose: []
        }
    }

    clearDataChoose = () => {
        this.setState({
            dataChoose: []
        })
    };


    onClickShape = (date, user, flagClick, member) => {
        let { isOwner, isAdmin, checkLogin } = this.props;
        if (flagClick) {
            if (this.props.infoRoster) this.props.infoRoster(flagClick);
            return;
        }
        let dataUser = {
            date: date,
            user: user
        };
        let dataChoose = this.state.dataChoose.slice(0);
        let flag = false;
        for (let i in dataChoose) {
            if (dataChoose[i].date === date && dataChoose[i].user && dataChoose[i].user.staff && user && user.staff && dataChoose[i].user.staff.id === user.staff.id) {
                dataChoose.splice(i, 1);
                this.setState({
                    dataChoose: dataChoose
                });
                flag = true;
                break;
            }
        }
        if (!flag) {
            if (!isOwner && isAdmin && member.role === 'ADMIN' && checkLogin && checkLogin.user && member && member.user && checkLogin.user.id === member.user.id && checkLogin.user.id !== member.user.id) {
                // if (!isOwner && isAdmin && member.role === 'ADMIN' && checkLogin && checkLogin.user && member && member.user && checkLogin.user.id !== member.user.id) {
                Config.popup.show('INFO', [Config.alertMess({ code: 'CREATEROSTERADMIN' })])
            }
            else {
                dataChoose.push(dataUser);
                this.setState({
                    dataChoose: dataChoose
                });
            }
        }

        if (this.props.getDataStaffChoose) this.props.getDataStaffChoose(dataChoose);
    };

    changeTimeToSecond = (time) => {
        let a = time.toString().split('.');
        let second = Math.round(parseFloat('0.' + (a[1] || 0)) * 60);
        second = second <= 9 ? '0' + parseInt(second, 10) : parseInt(second, 10);
        a = a[0] + ':' + second;
        return a;
    };

    resetWeek = () => {
        if (this.props.resetWeek) this.props.resetWeek();
    };

    render() {
        let { arrDay, resultFilter, memberList, user, onLoadingChange } = this.props;
        return (
            <div className="display_col full_w week-view">
                {!memberList &&
                    <div className="display_row align-center justify-center" style={{ marginTop: 100 }}>
                        <ReactLoading type={"spinningBubbles"} color={'#31CCBC'} height={30} width={30} />
                    </div>
                }
                {onLoadingChange && memberList &&
                    <div className="display_row align-center justify-center" style={{ position:'fixed',top: 460,right: 656 }}>
                        <ReactLoading type={"spinningBubbles"} color={'#31CCBC'} height={30} width={30} />
                    </div>
                }
                {/* <div className="roster-view scroll full_h_1" style={{height:'calc(100vh - 320px)'}}> */}
                <div className="roster-view scroll full_h_1">
                    <div className="roster-view" id={"week-view"} style={{ marginBottom: 40 }}>
                        {memberList && memberList.map((key, idx) => {
                            let arr = [];
                            if (resultFilter) {
                                arr = resultFilter.filter(i => i.staff && i.staff.id === key.user.id);
                            }
                            if (memberList) {
                                for (let i in memberList) {
                                    let a = arr.filter(j => j.staff.id === memberList[i].user.id);
                                    if (a.length === 0) {
                                        arr.push(
                                            {
                                                activities: [],
                                                staff: memberList[i].user
                                            }
                                        )
                                    }
                                }
                            }
                            if (key.status === 'INVITING' || (user && user !== key.user.id)) return null;
                            //if(key.status === 'INVITING' || key.role === 'OWNER' || (user && user!==key.user.id)) return null;

                            return (
                                <div className="roster-member-view display_row" key={idx + 'roster-view'}>
                                    <div className="roster-member-col">
                                        <Image src={(key && key.user && key.user.avatar) ? key.user.avatar : Config.avaDef} />
                                        <div className="grey-background" />
                                        <div className="stick-to-bottom">
                                            <div className="bottom-name-view">
                                                <EllipsisText
                                                    text={key.user.fullName.split(" ")[0]}
                                                    length={33}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {arrDay && arrDay.map((keyChild, idxChild) => {
                                        let choose = this.state.dataChoose.filter(i => (i.date === keyChild && i.user && i.user.staff && arr[0] && arr[0].staff && i.user.staff.id === arr[0].staff.id));
                                        let className = (choose && choose.length) > 0 ? 'border-def' : '';
                                        let hasRoster = false;
                                        if (arr.length > 0) {
                                            for (let i in arr[0].activities) {
                                                if (moment(keyChild).startOf('day').valueOf() === moment(arr[0].activities[i].time).startOf('day').valueOf()) {
                                                    hasRoster = arr[0].activities[i];
                                                    break;
                                                }
                                            }
                                        }

                                        let off = false;
                                        if (hasRoster && (!hasRoster.shift || hasRoster.isOff === 'Y')) {
                                            off = true
                                        }
                                        return (
                                            <div key={idxChild} className={"roster-member-col roster-time " + className}
                                                onClick={() => this.onClickShape(keyChild, arr[0], hasRoster, key)}>
                                                {hasRoster && !off && <div className="name-view time-info" style={{ backgroundColor: hasRoster.color }}>
                                                <div className='shittime'>
                                                    <span style={{ color: hasRoster.color !== 'transparent' ? 'white' : '#31ccbc' }}>
                                                        {this.changeTimeToSecond(hasRoster.shift.start)}
                                                    </span>
                                                    <span style={{ color: hasRoster.color !== 'transparent' ? 'white' : '#31ccbc' }}>
                                                        {this.changeTimeToSecond(hasRoster.shift.end)}
                                                    </span> 
                                                </div>
                                                    {hasRoster.note !== '' &&  (
                                                        <div>
                                                        <span className='noti-shift'>
                                                            <p className='shift-notes' style= {{ color: hasRoster.color !== 'transparent' ? 'white' : '#31ccbc' }}>Attached Shift Notes</p>
                                                            <Image className="logo-bell" src={require('../../assets/images/bell.png')} style= {{ color: hasRoster.color !== 'transparent' ? 'white' : '#31ccbc' }} />                                                   
                                                        </span>
                                                        </div>
                                                    )}                                                                                                       
                                                </div>}
                                                {hasRoster && off && <div className="name-view time-info txt-off"> OFF</div>}
                                            </div>                          
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

const MapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        zoom={16}
        center={{ lat: props.lat, lng: props.lng }}
    >
        {props.isMarkerShown &&
            <div>
                <Marker position={{ lat: props.lat, lng: props.lng }}
                    draggable={true}
                    icon={'https://teemie-web-app.s3.eu-west-1.amazonaws.com/Oval.png'}
                    onDragEnd={(e) => props.onMarkerDragEnd(e)}
                    options={
                        { optimized: true }
                    }
                />
                <Circle options={{
                    fillColor: '#31CCBC',
                    strokeColor: "#C7C7C7",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillOpacity: 0.35,
                }} center={{ lat: props.lat, lng: props.lng }}
                    radius={props.radius}
                />
            </div>
        }
    </GoogleMap>
));

class ActivateClock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            address: '',
            radius: 17,
            lat: 0,
            lng: 0,
            apiKey: "AIzaSyDRFmFO9UFDLT3qIwU9tfuAMA5XxUS4Dp4"
        };

        Geocode.setApiKey(this.state.apiKey);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.address !== this.props.address || prevProps.radius !== this.props.radius) {
            this.setState({ address: this.props.address, radius: this.props.radius });
        }
    }

    _handleClose = () => {
        this.setState({ page: 0, radius: 0 });
        if (this.props.handleClose) this.props.handleClose();
    };

    onPlaceSelected = async (place) => {
        let address = place.formatted_address;
        let lat = place.geometry.location.lat();
        let lng = place.geometry.location.lng();
        await this.setState({ address: address, lat: lat, lng: lng });
        console.log("lat ====>", this.state.lat)
        console.log("lng ====>", this.state.lng)
    }

    onSearch = () => {
        Geocode.fromAddress(this.state.address).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                this.setState({ page: 2, radius: 0 });
            },
            (error) => {
                console.error(error);
            }
        );
    }

    onMarkerDragEnd = (coord) => {
        let lat = coord.latLng.lat();
        let lng = coord.latLng.lng();

        Geocode.fromLatLng(lat, lng).then(
            (response) => {
                const address = response.results[0].formatted_address;
                this.setState({ address: address, lat: lat, lng: lng });
            },
            (error) => {
                console.error(error);
            }
        );
    }

    onChangeSlider = (e) => {
        const newVal = parseFloat(e.target.value);
        this.setState({ radius: newVal });
        this.positionSliderBubble();
        document.getElementById("myinput").oninput = function () {
            var value = (this.value - this.min) / (this.max - this.min) * 100
            this.style.background = 'linear-gradient(to right, #6AD5E5 0%, #00CDAC ' + value + '%, #fff ' + value + '%, white 100%)'
        };
    }

    positionSliderBubble = () => {
        let radius = `${(this.state.radius - 13) * 2.7}px`;
        const ele = document.querySelector('.bubble');
        if (ele) {
            ele.style.left = radius;
        }
        return radius;
    }

    saveClockOptions = () => {
        let params = {
            id: this.props.idTeam,
            is_clock_enabled: true,
            business_clock_in_address: this.state.address,
            business_clock_lat: this.state.lat,
            business_clock_long: this.state.lng,
            business_radius: this.state.radius

        }

        this.props.teamActions.editClockOptions(params, (err, res) => {
            if (err) {
                Config.popup.show('INFO', err.message);
            }
        });

        this.setState({ page: 3 })
    }

    onactiveClock = () => {
        this.setState({ page: 1 });
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this._handleClose} id="popup-clock-options" className='clock-in-popup'>
                <Modal.Body>
                    {this.state.page === 0 ? (
                        <div>
                            <div className="title-large">Confirm Clock in/Out</div>
                            <div className="content-roster-model" style={{ margin: `20px 0` }}> Please confirm that you would like to activate clock in/out? This will mean all staff will need to clock in and out when they are scheduled to work, and will add a column to your exported timesheets to track clocked in hours in addition to scheduled hours.</div>
                            <button type="button"
                                onClick={() => this.onactiveClock()}
                                className="btn-linear shadow clock-button"
                                style={{ width: 240, height: 50, margin: `50px 0 0 0 ` }}
                            >
                                NEXT
                            </button>
                        </div>
                    ) :
                    this.state.page === 1 ? (
                        <div>
                            <div className="title-large">Activate Clock In/Out</div>
                            <div className="content-roster-model">To activate this feature, please input your company address. You can then adjust the active zone around your business. Your team will then be able to clock in and out when arriving or leaving work.</div>
                            <div>
                                <Autocomplete
                                    id="foo"
                                    apiKey={this.state.apiKey}
                                    onPlaceSelected={(place) => {
                                        this.onPlaceSelected(place);
                                    }}
                                    onChange={(e) => this.setState({ address: e.target.value })}
                                    defaultValue={this.state.address}
                                    options={{
                                        types: []
                                    }}
                                />
                            </div>
                            <button type="button"
                                onClick={() => this.onSearch()}
                                className="btn-linear shadow clock-button"
                                style={{ width: 240, height: 50 }}
                            >
                                Next
                            </button>
                        </div>
                    ) : (this.state.page === 2 ? (
                        <div>
                            <div className="title-large">Activate Clock In/Out</div>
                            <div className="content-roster-model">To activate this feature, please input your company address. Your team will then be able to clock in and out when arriving or leaving work.</div>
                            <div>
                                {console.log("lat : ", this.state.lat)}
                                {console.log("lng : ", this.state.lng)}
                                <MapComponent
                                    isMarkerShown={true}
                                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${this.state.apiKey}&libraries=geometry,drawing,places`}
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `200px`, margin: `20px 0` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    lat={this.state.lat}
                                    lng={this.state.lng}
                                    radius={this.state.radius}
                                    onMarkerDragEnd={this.onMarkerDragEnd}
                                />
                                <div className="content">Adjust the slider to increase or decrease the activation zone around your business</div>

                                {/* <RangeStepInput 
                                    min={10} max={200}
                                    value={this.state.radius} step={1}
                                    onChange={e => this.onChangeSlider(e)}
                                /> */}
                            </div>
                            <div className='range_input_map'>
                                <input id="myinput" type="range" min="1" max="450" width={60} height={28} onChange={e => this.onChangeSlider(e)} value={this.state.radius} step={1} />
                            </div>                            <button type="button"
                                onClick={this.saveClockOptions}
                                className="btn-linear shadow clock-button"
                                style={{ width: 240, height: 50 }}
                            >
                                Confirm
                            </button>
                        </div>
                    ) : (
                        <div>
                            <div className="title-large">Clock In/Out Confirmed</div>
                            <div className="content-roster-model" style={{ margin: `20px 0` }}>Ok great. This feature is now ready and will be in place for your team on their apps right away. They will only be able to clock in or out when they are in the active zone you have created around your business location and their hours will reflect within their roster.</div>
                            <button type="button"
                                onClick={this._handleClose}
                                className="btn-linear shadow clock-button"
                                style={{ width: 240, height: 50, margin: `50px 0 0 0 ` }}
                            >
                                CLOSE
                            </button>
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
        )
    }
}

export default connect(state => ({
    checkLogin: state.main.checkLogin,
    teamList: state.team.teamList,
    memberList: state.team.memberList,
    eventList: state.team.eventList,
    shiftList: state.team.shiftList,
}),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        eventActions: bindActionCreators(eventActions, dispatch),
        shiftActions: bindActionCreators(shiftActions, dispatch),
        rosterActions: bindActionCreators(rosterActions, dispatch)
    })
)(Roster);
