import React,{Component} from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';

import '../register/register.css'
import '../my-team-detail/my-team-detail.css'
import '../calendar/calendar.css'
import './tasks-page.css'

import {browserHistory} from 'react-router';
import {Button} from "react-bootstrap";
import * as mainActions from "../../redux/main/main_actions";
import * as taskActions from "../../redux/task/task_actions";
import moment from 'moment';
import Config from "../../config";
import SearchBar from "../my-team-detail/search-bar";
import ReactLoading from "react-loading";
import {Image,Modal} from 'react-bootstrap';
import _ from 'lodash';
import Loader from '../loader/loader';

class TasksPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTaskComplete: false,
            taskList:null,
            isLoadMore: true,
            disLoadMore: false,
            fixedValue: 80
        };

        this.perPage = 4;
        this.numPage = 0;

        this.dataFilter = {
            limit: this.perPage,
            skip: this.numPage*this.perPage,
            sort: '[{"createdAt": "DESC"}]'
        }
    }

    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillMount() {
        // window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let scrollTop = window.pageYOffset;
        if(scrollTop > 5) {
            this.setState({
                fixedValue: 80
            });
        } else {
            this.setState({
                fixedValue: 80
            });
        }
    }

    getId = () => {
        let location = window.location.href;
        if(location.indexOf('?user=')){
            let memberId = window.location.search.split('?user=');
            memberId=memberId[memberId.length-1].split("&tid=");
            memberId=memberId[0];
            memberId = memberId || null;
            return memberId;
        }
        else{
            return null;
        }
    };

    _init =(page) =>{
        let condition = {};
        if((this.state.isLoadMore && this.state.taskList) || this.state.disLoadMore) return;
        this.setState({
            isLoadMore: true
        });

        let dataTemp = this.state.taskList ? this.state.taskList.slice(0) : null;
        if(dataTemp) this.numPage = this.numPage+1;
        else this.numPage= 0;

        let params = this.dataFilter;
        params.skip = this.numPage*this.perPage;
        let MemberId = this.getId();
        if(this.state.activeTaskComplete){
            condition = {team: this.state.idTeam, status:  "DONE"};
        }
        else{
            condition = {team: this.state.idTeam, status:  {"!=": "DONE"}};
        }

        if(MemberId){
            condition.assignTo = {like: '%'+MemberId+'%'};
        }

        if(page || page === 0){
            params.skip = 0;
        }     
        let checkLogin = localStorage.getItem('LOGIN');
        let UserId = null;
        if (checkLogin) {
            checkLogin = JSON.parse(checkLogin);
            UserId = checkLogin.user.id;
        }             
        params.userid = UserId;       
        params.teamId=this.state.idTeam;       
        params.condition = JSON.stringify(condition);

        this.props.taskActions.getListTask(params, (err, data)=>{
            if(err){
                Config.alertMess(err);
                this.numPage = this.numPage > 0 ? this.numPage - 1 : 0;
                this.setState({
                    taskList: [],
                    isLoadMore: false,
                    disLoadMore: 0
                });
            }
            else if(data){
                this.setState({
                    taskList: dataTemp && (!page && page!==0) ? dataTemp.concat(data) : data,
                    isLoadMore: false,
                    disLoadMore: data.length < this.perPage
                })
            }
        });
    };

    reload = () => {
        let params = {
            limit: this.dataFilter.limit + this.dataFilter.skip,
            skip:0,
            sort:this.dataFilter.sort,
            condition:this.dataFilter.condition
        };
        this.props.taskActions.getListTask(params, (err, data)=>{
            if(err){
                this.setState({
                    taskList: [],
                });
            }
            else if(data){
                this.setState({
                    taskList: data,
                })
            }
        });
    };

    _checkTitleChangeComplete = (flag) => {
        // console.log("flagflagflag", flag);
        if (flag === this.state.activeTaskComplete) return;
        this.numPage=0;
        this.dataFilter.skip=0;
        this.setState({
            activeTaskComplete: flag,
            disLoadMore: false,
            taskList:null
        },()=>{
            this._init();
        })
    };

    deleteTask = (item) => {
            this.props.taskActions.removeTask({id: item.id}, (err, data) => {
                if (data) {
                    this.reload();
                }
                else if (err) {
                    Config.alertMess(err);
                }
            });        
    };

    setDataListTeam = (data,id) => {
        this.setState({
            idTeam: this.state.idTeam ? this.state.idTeam : (id ? id : null)
        },()=>{
            this._init(0);
        });
    };

    changeDropDownTeam = (data) => {
        this.setState({
            idTeam: data.id,
            disLoadMore: false
        },()=>{
            this._init(0);
        });
    };


    render() {
        let {teamList,memberList} = this.props;
        let {taskList,isLoadMore} = this.state;
        let myTeam = [];
        if(teamList && this.state.idTeam){
            myTeam = teamList.find(i=>{return (i.id === this.state.idTeam)});
        }
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        return (
                <div className="register calendar-view row my-team-detail my-team roster-view full_w height-100vh">      
                    <div className="col-sm-12 display_col padding-0 full_h_1" style={{position:'relative'}}>
                        <div className="search-bar col-sm-12 shadow-1 display_row align-center sub-header-fixed" style={{top:this.state.fixedValue,width:'85%'}}>
                            <SearchBar isSearch={true}
                                       teamList={teamList}
                                       myTeam={myTeam}
                                       dataListTeam={this.setDataListTeam}
                                       changeDropDownTeam={this.changeDropDownTeam}/>
                        </div>
                        <div className="tasks-view full_h display_col task-fullview">
                            <div className="col-sm-12 padding-0 display_col" style={{height:'calc(100vh - 170px)'}}>
                                <ListTasksTab
                                    activeTask={this.state.activeTask}
                                    activeTaskComplete={this.state.activeTaskComplete}
                                    checkTitleChangeComplete={this._checkTitleChangeComplete}
                                />

                                <ListTasksView
                                    sampleData={taskList}
                                    loadMore={this._init}
                                    isLoadMore={isLoadMore}
                                    checkLogin={checkLogin}
                                    memberList={memberList}
                                    deleteTask={this.deleteTask}
                                    activeTaskComplete={this.state.activeTaskComplete}

                                />
                            </div>

                        </div>
                    </div>
                </div>
        )
    }

}

class ListTasksTab extends Component {

    constructor(props) {
        super(props);

        this.state = {};

    }

    _checkUpcoming = (flag) => {
        if (this.props.checkTitleChangeComplete) this.props.checkTitleChangeComplete(flag);
    };


    render() {
        let {activeTaskComplete} = this.props;
        return (
            <div className="title display_row align-between">
                <div className="display_row">
                    <span className={"completed_tasks " + (!activeTaskComplete ? "active" : '')}
                          onClick={() => this._checkUpcoming(false)}>
                        Upcoming Tasks
                    </span>
                    <span className={"upcoming_tasks " + (activeTaskComplete ? "active" : '')}
                          onClick={() => this._checkUpcoming(true)}>
                        Completed Tasks
                    </span>
                </div>
                <Button className="btn-linear-orange shadow" style={{width:145, height:35}}
                        onClick={()=>{browserHistory.push('create-tasks')}}>CREATE TASK</Button>
            </div>
        );
    }
}


class ListTasksView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            deletetasks:{},
            isUpdateRemoveTeamConfirmed:false,
        };

    }

    isJSON = ( str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return [];
        }
        return JSON.parse(str);
    };

    deleteTask = (item) => {
        if(this.props.deleteTask) this.props.deleteTask(item);
    };

    handleScrollFeed = (event) => {
        if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
            if(this.props.loadMore) this.props.loadMore();
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', _.debounce(this.handleScrollFeed,200));
    }

    _removeTask = (item) => {
        this.setState({deletetasks:item});
        this.setState({ isUpdateRemoveTeamConfirmed: true });
      };

    _ConfirmRemove = (item) => {
        this.deleteTask(item)
        setTimeout(() => {
            this._handleClose();                    
          }, 300);
        };

    _handleClose = () => {
        this.setState({ isUpdateRemoveTeamConfirmed: false });        
      };

    render() {
        let {sampleData,isLoadMore,memberList,checkLogin,activeTaskComplete} = this.props;
        
        return (
            <div className="full_h_1 scroll" onScroll={this.handleScrollFeed} style={{height:'100%'}}>
                <div className="task-list-view display_row" style={{height:'100%'}}>
                    <div className="member-information animation-show col-sm-12"
                         style={{marginBottom: 20}} ref={e=>this.listView=e}>
                        {sampleData && sampleData.map((key, idx) => {
                            // if ((activeTaskComplete && deadline<0) || (!activeTaskComplete && deadline>=0) || !key.assignTo || !key.deadline) return null;
                            let assignTo = key.assignTo && this.isJSON(key.assignTo) ? JSON.parse(key.assignTo) : [];
                            let allStaff = memberList ? memberList.filter(i=> i.role !== 'OWNER' && (i.user && checkLogin && checkLogin.user && i.user.id !== checkLogin.user.id)) : [];
                            return (
                                    <div className="shadow-1 info-in display_row" style={{cursor:'pointer'}} key={"data-" + idx}>
                                        <div className="left_images" style={{paddingRight: assignTo.length >= 3 ? 80 : 0}}
                                             onClick={()=>{browserHistory.push(`/task-detail?taskId=${key.id}&userId=${checkLogin.user.id}`)}}
                                        >
                                            {assignTo.map((keyx, idxx) => {
                                                if (idxx > 3) return null;
                                                let cssImage = 'image-4';
                                                if(assignTo.length <4){
                                                    cssImage = 'image-'+assignTo.length;
                                                }
                                                return (
                                                    <img src={`${keyx.user && keyx.user.avatar ? keyx.user.avatar : Config.avaDef}`}
                                                         key={"image-item" + idxx}
                                                         alt={key.title}
                                                         className={cssImage}
                                                    />
                                                )}
                                            )}

                                            {assignTo.length > 4 && <div className={"plus_add"}>{assignTo.length -  4}+</div>}
                                        </div>
                                        <div className="right_content"
                                             onClick={()=>{browserHistory.push(`/task-detail?taskId=${key.id}?userId=${checkLogin.user.id}`)}}
                                        >
                                            <h5 className="task_title"> {key.name}</h5>
                                            {/* <p className="task_shortdes">{key.description ? key.description : 'N/A'}</p> */}
                                            <p className="assignto">Assigned to {allStaff.length === assignTo.length ? 'All staffs' : assignTo.map((e, idx)=>e.name+ (idx !== assignTo.length-2 ? (idx!== assignTo.length-1 ? ", " : '') : ' and '))}</p>
                                            {key.status !== 'DONE' && <p className="assignto datetimes">TBC by {moment(key.deadline).format('HH:mm DD/MM/YYYY')}</p>}
                                            {key.status === 'DONE' && <p className="assignto datetimes completed">Completed</p>}
                                        </div>

                                        {/*{key.status === 'DONE' &&*/}
                                        <span className="btn_task_delete" style={{zIndex:2}}
                                              onClick={()=>this._removeTask(key)}>
                                            <img src={require('../../assets/images/button_tasks_delete.png')}
                                                 alt={"close"} title={"close"}/>
                                        </span>
                                        {/*}*/}
                                    </div>
                                )
                            })}
                        {!sampleData &&
                             <div className="display_row align-center justify-center" style={{width:'100%',
                             borderRadius: 25,
                             padding: 10,
                             position: 'absolute',
                             height: '100%'
                         }}>
                             <ReactLoading type={"spinningBubbles"} color={'#31CCBC'} height={30} width={30}/>
                         </div>
                        }    
                        {!sampleData || (sampleData && sampleData.length===0) &&
                            <div className="full_w display_row align-center justify-center h-100 align-center">
                                <div className="txt-not-task no-content">
                                {activeTaskComplete ? "Your completed task list will appear here":"Your assigned task list will appear here"}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div>
                <Modal
                show={this.state.isUpdateRemoveTeamConfirmed}
                onHide={this.closeModal}
                id="popup-change-plan"
                className={"root-popup-change-plan remove-team-member"}
                style={{  maxWidth: "100%", margin: "auto" }}
                >
            
            <Modal.Body>
              <Image
                className="logo remove-member-logo"
                src={require("../../assets/images/register/icon-member.png")}
                />
                <div className="title">REMOVE TASK</div>
                <div className="title1 remove-admin">Do you want to remove this task?</div>
                <div className="button_display_row" style={{ padding: "10px 0" }}>
                
                <Button
                  className="login_btn_login btn-linear-orange shadow"
                  style={{ maxHeight: 50, margin: "0 5px" }}
                  onClick={this._handleClose}
                >
                  CANCEL
                </Button>
                <Button
                  className="login_btn_login btn-linear shadow"
                  style={{ maxHeight: 50, margin: "0 5px" }}
                  onClick={()=>this._ConfirmRemove(this.state.deletetasks)}
                >
                  CONFIRM
                </Button>
                </div>
                   </Modal.Body>
                </Modal>
                </div>
            </div>
        )

    }

}


export default connect(state => ({
        memberList: state.team.memberList,
        taskList: state.task.taskList,
        checkLogin : state.main.checkLogin,
        teamList: state.team.teamList,
    }),
    (dispatch) => ({
        mainActions: bindActionCreators(mainActions, dispatch),
        taskActions: bindActionCreators(taskActions, dispatch),
    })
)( TasksPage);